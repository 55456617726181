import React, { useState } from 'react'
import { Table, Tag, Button } from 'antd'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_DISCOUNTS } from '../../../graphql/queries'
import DiscountDetails from './components/details';
import { DAYS_TO_STRING } from '../../../utils/days';

const { Column } = Table;
const PAGE_SIZE = 25;

export default function Discounts() {
	const [after, setAfter] = useState(0);
	const [visible, setVisible] = useState(false);
	const [discount, setDiscount] = useState(null);
	const { data, loading, refetch } = useQuery(INDEX_DISCOUNTS, { variables: { first: PAGE_SIZE, after }, fetchPolicy: 'network-only' });

	function handleTableChange(pagination, filters, sorter) {
		const currentPage = pagination.current - 1;
		setAfter(currentPage * PAGE_SIZE);
		refetch();
	};

	function openDrawer(discount) {
		setDiscount(discount);
		setVisible(true);
	}

	function onClose() {
		setDiscount(null);
		setVisible(false);
		refetch();
	}


	return (
		<>
			<Button style={{ marginBottom: '16px' }} onClick={() => openDrawer(null)}>
				New Discount
			</Button>
			<DiscountDetails
				visible={visible}
				onClose={onClose}
				discount={discount}
			/>
			<Table
				rowKey={record => record.node.id}
				dataSource={data ? data.adminIndexDiscounts.edges : []}
				pagination={{
					showSizeChanger: false,
					pageSize: PAGE_SIZE,
					defaultCurrent: 1,
					total: data && data.adminIndexDiscounts.totalCount
				}}
				bordered
				loading={loading}
				onChange={handleTableChange}
			>
				<Column
					title="Name"
					key="name"
					render={(text, row) => {
						return (
							<span>
								{row.node.name?.en}
							</span>
						)
					}
					}
				/>
				<Column
					title="Discount percentage"
					key="discountPercentage"
					render={(text, row) => {
						return (
							<span>
								{row.node.discountPercentage} <strong>%</strong>
							</span>
						)
					}
					}
				/>
				<Column
					title="Discount type"
					key="discountType"
					render={(text, row) => {
						return (
							<span>
								{row.node.discountType === 'Pizza' && <Tag>Pizzas</Tag>}
								{row.node.discountType === 'MenuItem' && <Tag>Menu Items</Tag>}
							</span>
						)
					}
					}
				/>
				<Column
					title="Discount items"
					key="isAllItems"
					render={(text, row) => {
						return (
							<span>
								{row.node.isAllItems && row.node.discountType === 'Pizza' && <Tag>All menu pizzas</Tag>}
								{!row.node.isAllItems && row.node.discountType === 'Pizza' && <Tag>Specific menu pizzas</Tag>}
								{row.node.isAllItems && row.node.discountType === 'MenuItem' && <Tag>All menu items</Tag>}
								{!row.node.isAllItems && row.node.discountType === 'MenuItem' && <Tag>Specific menu items</Tag>}
							</span>
						)
					}
					}
				/>
				<Column
					title="Delivery Method"
					key="deliveryMethod"
					render={(text, row) => {
						return (
							<span>
								{row.node.isDelivery && <Tag>Delivery</Tag>}
								{row.node.isPickup && <Tag>Pickup</Tag>}
							</span>
						)
					}
					}
				/>
				<Column
					title="Discount valid days"
					key="discountValidDays"
					render={(text, row) => {
						if (row.node.discountValidDays.length === 7) {
							return <Tag>All days</Tag>
						}
						return row.node.discountValidDays.map((item) => <Tag>{DAYS_TO_STRING[item]}</Tag>)
					}
					}
				/>
				<Column
					title="Action"
					key="action"
					render={(text, record) => (
						<span>
							<Button onClick={() => openDrawer(record.node)} type='link'>
								Edit
							</Button>
						</span>
					)}
				/>
			</Table>
		</>
	);
}
