import React from 'react'
import {
	Drawer,
	Button,
	Row,
	message,
	Col,
} from 'antd'
import { Formik } from 'formik';
import { Form, Input, Radio } from 'formik-antd'
import * as Yup from 'yup'
import { useMutation } from '@apollo/react-hooks'
import { ADMIN_UPDATE_MENU_CATEGORY, ADMIN_CREATE_MENU_CATEGORY, ADMIN_DELETE_MENU_CATEGORY } from '../../../../graphql/mutations'
import { displayGraphqlErrors } from '../../../../graphql/utils'

const MenuCategorySchema = Yup.object().shape({
	name: Yup.object().shape({
		en: Yup.string().required('[EN] Name is required'),
		is: Yup.string().required('[IS] Name is required'),
	}),
	active: Yup.boolean()
});

export default function MenuCategoryDetails({ visible, onClose, menuCategory }) {
	const [adminUpdateMenuCategory] = useMutation(ADMIN_UPDATE_MENU_CATEGORY)
	const [adminCreateMenuCategory] = useMutation(ADMIN_CREATE_MENU_CATEGORY)
	const [adminDeleteMenuCategory] = useMutation(ADMIN_DELETE_MENU_CATEGORY)

	async function handleDelete () {
		try {
			const { data } = await adminDeleteMenuCategory({ variables: { id: menuCategory.id } })
			message.info(data.adminDeleteMenuCategory.message)
			onClose(true)
		} catch (err) {
			displayGraphqlErrors(err)
		}
	}

	return (
		<Drawer
			title={menuCategory ? 'Edit Category' : 'Create Category'}
			width={720}
			onClose={onClose}
			visible={visible}
			bodyStyle={{ paddingBottom: 80 }}
		>
			<Formik
				enableReinitialize={true}
				initialValues={{
					name: menuCategory ? menuCategory.name : { en: '', is: '' },
					active: menuCategory ? menuCategory.active : true
				}}
				validationSchema={MenuCategorySchema}
				onSubmit={async values => {
					try {
						if (menuCategory && menuCategory.id) {
							await adminUpdateMenuCategory({ variables: { id: menuCategory.id, input: values }} );
						} else {
							await adminCreateMenuCategory({ variables: { input: values } })
						}
						onClose(true)
					} catch (err) {
						displayGraphqlErrors(err)
					}
				}}
			>
				{({ errors, touched, handleSubmit, values }) => {
					return (
						<>
							<Form
								layout='vertical'
								hideRequiredMark
							>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item label="Name [IS]" name='name.is' validate={errors.name && errors.name.is && touched.name && touched.name.is}>
											<Input
												name='name.is'
												value={values.name.is}
												placeholder="Name [IS]"
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Name [EN]" name='name.en' validate={errors.name && errors.name.en && touched.name && touched.name.en}>
											<Input
												name='name.en'
												value={values.name.en}
												placeholder="Name [EN]"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Form.Item label="Is active" name='active' validate={errors.active && touched.active}>
									<Radio.Group name='active' value={values.active}>
										<Radio.Button value={true}>Active</Radio.Button>
										<Radio.Button value={false}>Not active</Radio.Button>
									</Radio.Group>
								</Form.Item>
							</Form>
							<div
								style={{
									position: 'absolute',
									right: 0,
									bottom: 0,
									width: '100%',
									borderTop: '1px solid #e9e9e9',
									padding: '10px 16px',
									background: '#fff',
									textAlign: 'right',
								}}
								>
								<div style={{
									display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
								}}>
									<div>
										{menuCategory &&
											<Button onClick={handleDelete} type='danger'>
												Delete menu category
											</Button>
										}
									</div>
									<div>
										<Button onClick={onClose} style={{ marginRight: 8 }}>
											Cancel
										</Button>
										<Button onClick={handleSubmit} type="primary">
											Save
										</Button>
									</div>
								</div>
							</div>
						</>	
					)
				}}
			</Formik>
		</Drawer>
	)
}
