export const groupMenuItems = menuItems => {
	var groups = { };
	menuItems.forEach(function (item) {
		let { node } = item;
		var list = groups[node.category.name.en];
		if (list) {
			list.menuItems.push(node);
		} else {
			groups[node.category.name.en] = {
				menuItems: [node]
			}
		}
	});
	return groups;
}
