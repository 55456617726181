import React from 'react'
import {
	Row,
	message
} from 'antd'
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd'
import * as Yup from 'yup'
import { useMutation } from '@apollo/react-hooks'
import { ADMIN_UPDATE_BRANCH_TIME } from '../../../../graphql/mutations'
import { displayGraphqlErrors } from '../../../../graphql/utils'

const TimesInputSchema = Yup.object().shape({
	estimatedDeliveryTime: Yup.number().min(0, 'Estimated delivery time cannot be lower than 0'),
	estimatedPickupTime: Yup.number().min(0, 'Estimated pickup time cannot be lower than 0'),
});


// Renders the ETA on delivery and pickup times.
export default function EstimatedTimesInput ({ branch }) {
	const [adminUpdateBranchTime] = useMutation(ADMIN_UPDATE_BRANCH_TIME)

	if (!branch) {
		return null;
	}

	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				estimatedDeliveryTime: branch.estimatedDeliveryTime,
				estimatedPickupTime: branch.estimatedPickupTime
			}}
			validationSchema={TimesInputSchema}
			onSubmit={async values => {
				try {
					const { data } = await adminUpdateBranchTime({ variables: { id: branch.id, input: values }} );
					message.info(data.adminUpdateBranchTime.message)
				} catch (err) {
					displayGraphqlErrors(err)
				}
			}}
		>
			{({ errors, touched, handleSubmit, setFieldValue, values }) => {
				return (
					<>
						<Form
							layout='vertical'
							hideRequiredMark
						>
							<Row style={{ display: 'flex', flexDirection: 'row', maxWidth: '500px'}}>
								<Form.Item label="Estimated Delivery Time" name='estimatedDeliveryTime' validate={errors.estimatedDeliveryTime && touched.estimatedDeliveryTime}>
									<Input
										type='number'
										name='estimatedDeliveryTime'
										value={values.estimatedDeliveryTime}
										placeholder="Estimated Delivery Time"
										onBlur={handleSubmit}
										suffix="Minutes"
									/>
								</Form.Item>
								<Form.Item label="Estimated Pickup Time" name='estimatedPickupTime' validate={errors.estimatedPickupTime && touched.estimatedPickupTime}>
									<Input
										type='number'
										name='estimatedPickupTime'
										value={values.estimatedPickupTime}
										placeholder="Estimated Pickup Time"
										onBlur={handleSubmit}
										suffix="Minutes"
									/>
								</Form.Item>
							</Row>
						</Form>
					</>	
				)
			}}
		</Formik>
	)
}
