import React, { useState } from 'react'
import { Table, Button, Tag } from 'antd'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_TOPPINGS } from '../../../../graphql/queries'
import ToppingDetails from '../components/toppingDetail';

const { Column, ColumnGroup } = Table;
const PAGE_SIZE = 25;
export default function ToppingTable() {
	const [after, setAfter] = useState(0)
	const [visible, setVisible] = useState(false)
	const [topping, setTopping] = useState(null)
	const { data, loading, refetch } = useQuery(INDEX_TOPPINGS, { variables: { first: PAGE_SIZE, after }, fetchPolicy: 'network-only' })
	
	function handleTableChange (pagination, filters, sorter) {
		const currentPage = pagination.current - 1
		setAfter(currentPage * PAGE_SIZE)
		refetch()
	};

	function openDrawer (topping) {
		setTopping(topping)
		setVisible(true)
	}
	
	function onClose () {
		setTopping(null)
		setVisible(false)
		refetch()
	}

	return (
		<>
			<Button style={{marginBottom: '16px'}} onClick={() => openDrawer(null)}>
				New Topping
			</Button>
			<ToppingDetails
				visible={visible}
				onClose={onClose}
				topping={topping}
			/>
			<Table
				rowKey={record => record.node.id}
				dataSource={data ? data.adminIndexToppings.edges : []}
				pagination={{
					showSizeChanger: false,
					pageSize: PAGE_SIZE,
					defaultCurrent: 1,
					total: data && data.adminIndexToppings.totalCount
				}}
				loading={loading}
				bordered
				onChange={handleTableChange}
			>
				<Column
					title="Name"
					key="name"
					render={(text, row) => {
						return (
							<span>
								{row.node.name.en}
							</span>
						)
					}}
				/>
				<ColumnGroup
					title='Prices'
					key="prices"
				>
					<Column
						title="Small"
						key="priceSmall"
						render={(text, row) => {
							return (
								<span>
									{row.node.priceSmall} <strong>kr.</strong>
								</span>
							)
						}}
					/>
					<Column
						title="Medium"
						key="priceMedium"
						render={(text, row) => {
							return (
								<span>
									{row.node.priceMedium} <strong>kr.</strong>
								</span>
							)
						}}
					/>
					<Column
						title="Large"
						key="priceLarge"
						render={(text, row) => {
							return (
								<span>
									{row.node.priceLarge} <strong>kr.</strong>
								</span>
							)
						}}
					/>
				</ColumnGroup>
				<Column
					title="Category"
					key="category"
					render={(text, row) => {
						return (
							<span>
								<Tag color={'geekblue'} key={row.node.id}>
									{row.node.category.name.en}
								</Tag>
							</span>
						)
					}
					}
				/>
				<Column
					title="Action"
					key="action"
					render={(text, record) => (
						<span>
							<Button onClick={() => openDrawer(record.node)} type='link'>
								Edit
							</Button>
						</span>
					)}
				/>
			</Table>
		</>
	);
}
