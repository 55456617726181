import React, { useState } from 'react'
import { Result, Tabs, Button } from 'antd'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_BRANCHES } from '../../../graphql/queries'
import { LoadingOutlined } from '@ant-design/icons'
import BranchDetail from './components/branchDetail'
import { PlusCircleOutlined } from '@ant-design/icons';

const { TabPane } = Tabs
export default function Branch() {
	const [activeKey, setActiveKey] = useState('0')
	const [create, setCreate] = useState(false)

	const { data, refetch } = useQuery(INDEX_BRANCHES, { variables: { first: 100, after: 0 }});

	function onChange (activeKey) {
		setActiveKey(activeKey)
		setCreate(false)
	}

	function onNew (){
		setCreate(true)
		setActiveKey('-1')
	}

	async function onCreate () {
		await refetch();
		setActiveKey(data.adminIndexBranches.edges.length.toString()) 
	}

	if (data && data.adminIndexBranches) {
		return (
			<>
				<Tabs
					onChange={onChange}
					activeKey={activeKey}
					tabBarExtraContent={<Button type='primary' icon={<PlusCircleOutlined />} onClick={onNew}>New Location</Button>}
				>
					{data.adminIndexBranches.edges.map(({ node }, index) => {
						return (
							<TabPane tab={node.name} key={index.toString()}>
								<BranchDetail branch={node}/>
							</TabPane>
						)
					})}
					{create &&
						<TabPane
							tab={
								<span>
									<PlusCircleOutlined />
									New Location
								</span>
							}
							key={'-1'}
						>
							<BranchDetail branch={null} onCreate={onCreate}/>
						</TabPane>
					}
				</Tabs>
			</>
		)
	} else {
		return (
			<Result
				icon={<LoadingOutlined spin/>}
			/>
		)
	}
}
