import React, { useState } from 'react'
import { Table, Button, Tag } from 'antd'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_PIZZAS } from '../../../../graphql/queries'
import PizzaDetails from '../components/pizzaDetail'

const { Column, ColumnGroup } = Table;
const PAGE_SIZE = 25;
export default function PizzaTable () {
	const [after, setAfter] = useState(0)
	const [visible, setVisible] = useState(false)
	const [pizza, setPizza] = useState(null);
	const { data, loading, refetch } = useQuery(INDEX_PIZZAS, { variables: { first: PAGE_SIZE, after }, fetchPolicy: 'network-only' });

	function handleTableChange (pagination, filters, sorter) {
		const currentPage = pagination.current - 1;
		setAfter(currentPage * PAGE_SIZE);
		refetch();
	};

	function openDrawer (pizza) {
		setPizza(pizza);
		setVisible(true);
	}
	
	function onClose () {
		setPizza(null);
		setVisible(false);
		refetch();
	}

	return (
		<>
			<Button style={{marginBottom: '16px'}} onClick={() => openDrawer(null)}>
				New Pizza
			</Button>
			<PizzaDetails
				visible={visible}
				onClose={onClose}
				pizza={pizza}
			/>
			<Table
				rowKey={record => record.node.id}
				dataSource={data ? data.adminIndexPizzas.edges : []}
				pagination={{
					showSizeChanger: false,
					pageSize: PAGE_SIZE,
					defaultCurrent: 1,
					total: data && data.adminIndexPizzas.totalCount
				}}
				bordered
				loading={loading}
				onChange={handleTableChange}
			>
				<Column
					title="Name"
					key="name"
					render={(text, row) => {
						return (
							<span>
								{row.node.name.en}
							</span>
						)
					}
					}
				/>
				<ColumnGroup
					title='Prices'
					key="prices"
				>
					<Column
						title="Small"
						key="priceSmall"
						render={(text, row) => {
							return (
								<span>
									{row.node.priceSmall} <strong>kr.</strong>
								</span>
							)
						}}
					/>
					<Column
						title="Medium"
						key="priceMedium"
						render={(text, row) => {
							return (
								<span>
									{row.node.priceMedium} <strong>kr.</strong>
								</span>
							)
						}}
					/>
					<Column
						title="Large"
						key="priceLarge"
						render={(text, row) => {
							return (
								<span>
									{row.node.priceLarge} <strong>kr.</strong>
								</span>
							)
						}}
					/>
				</ColumnGroup>
				<Column
					title="Toppings"
					key="toppings"
					render={(text, row) => {
						return row.node.toppings.map((obj) => <Tag>{obj.amount > 1 ? `${obj.amount} x ` : ''} {obj.topping.name.en}</Tag>)
					}}
				/>
				<Column
					title="Action"
					key="action"
					render={(text, record) => (
						<span>
							<Button onClick={() => openDrawer(record.node)} type='link'>
								Edit
							</Button>
						</span>
					)}
				/>
			</Table>
		</>
	)
}
