// Router Types
import { AdminRoute, PublicRoute, EmployeeRoute } from '../components/routes'

// Container types
import { DefaultContainer, AdminContainer } from '../components/containers'

// Routes
import Login from './admin/login'

// Employee
import Orders from './admin/order'
import Customer from './admin/customer'
import Branch from './admin/branch'

// Admin
import { EditOffer, CreateOffer } from './admin/offer'
import Offers from './admin/offers'
import Product from './admin/product'
import Front from './admin/front'
import Employee from './admin/employee'
import ChangeLog from './admin/changelog'
import Pizza from './admin/pizza'
import Discount from './admin/discount'
import CastelloSettings from './admin/castello_settings'
import Data from './admin/data'


export default [
  {
    path: '/login',
    exact: true,
    component: Login,
    routeType: PublicRoute,
    layout: DefaultContainer
	},
	{
		path: '/',
		exact: true,
		component: Orders,
		routeType: EmployeeRoute,
		layout: AdminContainer
	},
	{
		path: '/branch',
		exact: true,
		component: Branch,
		routeType: EmployeeRoute,
		layout: AdminContainer
	},
	{
		path: '/branch/:id',
		exact: false,
		component: Branch,
		routeType: EmployeeRoute,
		layout: AdminContainer
	},
	{
		path: '/customer',
		exact: true,
		component: Customer,
		routeType: EmployeeRoute,
		layout: AdminContainer
	},
	{
		path: '/product',
		exact: true,
		component: Product,
		routeType: AdminRoute,
		layout: AdminContainer
	},
	{
		path: '/pizza',
		exact: true,
		component: Pizza,
		routeType: AdminRoute,
		layout: AdminContainer
	},
	{
		path: '/offers',
		exact: true,
		component: Offers,
		routeType: AdminRoute,
		layout: AdminContainer
	},
	{
		path: '/offers/:id/edit',
		component: EditOffer,
		routeType: AdminRoute,
		layout: AdminContainer
	},
	{
		path: '/offers/create',
		component: CreateOffer,
		routeType: AdminRoute,
		layout: AdminContainer
	},
	{
		path: '/employee',
		exact: true,
		component: Employee,
		routeType: AdminRoute,
		layout: AdminContainer
	},
	{
		path: '/changelog',
		exact: true,
		component: ChangeLog,
		routeType: AdminRoute,
		layout: AdminContainer
	},
	{
		path: '/discount',
		exact: true,
		component: Discount,
		routeType: AdminRoute,
		layout: AdminContainer
	},
	{
		path: '/castello-settings',
		exact: true,
		component: CastelloSettings,
		routeType: AdminRoute,
		layout: AdminContainer
	},
	{
		path: '/order-data',
		exact: true,
		component: Data,
		routeType: EmployeeRoute,
		layout: AdminContainer
	},
	{
		path: '/front',
		exact: true,
		component: Front,
		routeType: AdminRoute,
		layout: AdminContainer
	}
]