import React from 'react'
import { Form, Radio } from 'formik-antd'

const sizes = [{
	value: 'small',
	label: 'Small'
}, {
	value: 'medium',
	label: 'Medium'
}, {
	value: 'large',
	label: 'Large'
}]

export default function PizzaSizeSelect ({ name, value }) {
	return (
		<Form.Item label={'What size of pizza ?'} name={name}>
			<Radio.Group buttonStyle="solid" name={name} value={value}>
				{sizes.map((item, index) => {
					return <Radio.Button value={item.value}>{item.label}</Radio.Button>
				})}
			</Radio.Group>
		</Form.Item>
	)
}
