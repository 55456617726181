import React from 'react'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_PIZZAS } from '../../../../../graphql/queries'
import { Select } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const { Option } = Select;


export default function PizzaSelect ({ onChange, value }) {
	const { data, loading } = useQuery(INDEX_PIZZAS, { variables: { first: 100, after: 0 }, fetchPolicy: 'network-only' });
	if (loading || !data) {
		return <LoadingOutlined spin/>
	}

	return (
		<Select
			mode="multiple"
			style={{ width: '100%' }}
			placeholder="Please select"
			value={value}
			onChange={onChange}
		>
			{data.adminIndexPizzas.edges.map(({ node }, index) => {
				return <Option key={index} value={node.id}>{node.name.en}</Option>
			})}
		</Select>
	)
}
