import jwt_decode from 'jwt-decode';
import { message } from 'antd';
const AUTH_TOKEN = 'auth-token';

export const getToken = () => localStorage.getItem(AUTH_TOKEN);
export const setToken = token => localStorage.setItem(AUTH_TOKEN, token);
export const deleteToken = () => localStorage.removeItem(AUTH_TOKEN);

export const setTokenAsync = async token => {
	return Promise.resolve().then(function () {
			localStorage.setItem(AUTH_TOKEN, token);
	});
}

export const isTokenExpired = (token) => {
	return token.exp * 1000 < new Date().getTime();
}

export const decodeToken = (token) => {
	try {
		var decoded = jwt_decode(token);
		return decoded;
	} catch (err) {
		// JWT is malformed delete it and throw err.
		deleteToken();
		message.error('Villa kom upp.')
		throw err;
	}
}

export const isAdmin = () => {
	try {
		let token = getToken();
		if (!!token) {
			let decoded = decodeToken(token);
			if (isTokenExpired(decoded)){
				deleteToken();
				return false;
			}
			return decoded.role === 'admin';
		}
		return false;
	} catch (err) {
		return false
	}
}

export const isEmployee = () => {
	try {
		let token = getToken();
		if (!!token) {
			let decoded = decodeToken(token);
			if (isTokenExpired(decoded)){
				deleteToken();
				return false;
			}
			let { role } = decoded;
			return role === 'employee' || role === 'admin';
		}
		return false;
	} catch (err) {
		return false
	}
}

export const getRole = () => {
	try {
		let token = getToken()
		if (!!token) {
			let decoded = decodeToken(token)
			if (isTokenExpired(decoded)){
				deleteToken();
				return false;
			}
			return decoded.role
		}
		return null
	} catch (err) {
		return null
	}
}

export const getName = () => {
	try {
		let token = getToken()
		if (!!token) {
			let decoded = decodeToken(token)
			if (isTokenExpired(decoded)){
				deleteToken();
				return false;
			}
			return decoded.name
		}
		return null
	} catch (err) {
		return null
	}
}