import React, { useState, useEffect } from 'react'
import {
	Drawer,
	Button,
	Row,
	Col,
	message,
	Select,
	Typography
} from 'antd'
import { Formik } from 'formik'
import { Form, Input, Radio, Checkbox } from 'formik-antd'
import * as Yup from 'yup'
import { useMutation } from '@apollo/react-hooks'
import { ADMIN_CREATE_DISCOUNT, ADMIN_UPDATE_DISCOUNT, ADMIN_DELETE_DISCOUNT } from '../../../../graphql/mutations'
import { displayGraphqlErrors } from '../../../../graphql/utils'
import { DAYS, DAYS_OPTIONS } from '../../../../utils/days'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_PIZZAS, INDEX_MENU_ITEMS } from '../../../../graphql/queries'
import { LoadingOutlined } from '@ant-design/icons'
import { groupMenuItems } from '../../../../services/menu_items';
import _ from 'lodash';
const { Option, OptGroup } = Select;

const DiscountSchema = Yup.object().shape({
	name: Yup.object().shape({
		en: Yup.string().required('[EN] Name is required'),
		is: Yup.string().required('[IS] Name is required'),
	}),
	description: Yup.object().shape({
		en: Yup.string().nullable(),
		is: Yup.string().nullable(),
	}),
	isDelivery: Yup
		.boolean()
		.nullable()
		.test('is-delivery-test', 'You must specify either delivery or pickup', function(value) {
			// Return false, if isPickup is set to false AND is delivery is set to false.
			if (!value && !this.parent.isPickup) {
				return false
			}
			return true;
		}),
	isPickup: Yup
		.boolean()
		.nullable()
		.test('is-pickup-test', 'You must specify either delivery or pickup', function(value) {
			// Return false, if isPickup is set to false AND is delivery is set to false.
			if (!value && !this.parent.isDelivery) {
				return false
			}
			return true;
		}),
	discountPercentage: Yup.number().min(0, 'Discount cannot be lower than 0').max(100, 'Discount cannot be larger than 100').required('Discount is required').typeError('Discount is required'),
	discountValidDays: Yup.array(Yup.string()).min(1, 'You need to select at least one day').max(7),
	active: Yup.boolean(),
	discountType: Yup
		.string()
		.oneOf(['Pizza', 'MenuItem'])
		.typeError('Discount type is required'),
	isAllItems: Yup.boolean().nullable(),
	allowedItems: Yup
		.array(Yup.string())
		.nullable()
		.test('is-all-items-selected', 'You must select what items are allowed', function(value) {
			// Check if all items is allowed or not
			if (!this.parent.isAllItems) {
				return value.length > 0;
			}
			return true;
		})
});

export default function DiscountDetails({ visible, onClose, discount }) {
	const [adminUpdateDiscount] = useMutation(ADMIN_UPDATE_DISCOUNT)
	const [adminCreateDiscount] = useMutation(ADMIN_CREATE_DISCOUNT)
	const [adminDeleteDiscount] = useMutation(ADMIN_DELETE_DISCOUNT)

	async function handleDelete () {
		try {
			const { data } = await adminDeleteDiscount({ variables: { id: discount.id } })
			message.info(data.adminDeleteDiscount.message)
			onClose(true)
		} catch (err) {
			displayGraphqlErrors(err)
		}
	}

	return (
		<Drawer
			title={discount ? 'Edit Discount' : 'Create Discount'}
			width={720}
			onClose={onClose}
			visible={visible}
			bodyStyle={{ paddingBottom: 80 }}
		>
			<Formik
				enableReinitialize={true}
				initialValues={{
					name: discount ? discount.name : { en: '', is: '' },
					description: discount ? discount.description : { en: '', is: ''},
					isDelivery: discount ? discount.isDelivery : false,
					isPickup: discount ? discount.isPickup : false,
					isAllItems: discount ? discount.isAllItems : true,
					discountPercentage: discount ? discount.discountPercentage : null,
					discountValidDays: discount ? discount.discountValidDays : [],
					active: discount ? discount.active : true,
					allowedItems: discount ? discount.allowedItems : [],
					discountType: discount ? discount.discountType : null
				}}
				validationSchema={DiscountSchema}
				onSubmit={async values => {
					try {
						// Because of some formik fuckup, undefined key is sometimes in values for god knows what reason
						let input = _.omit(values, ['undefined']);
						if (discount && discount.id) {
							await adminUpdateDiscount({ variables: { id: discount.id, input }} );
						} else {
							await adminCreateDiscount({ variables: { input } })
						}
						onClose(true)
					} catch (err) {
						displayGraphqlErrors(err)
					}
				}}
			>
				{({ errors, touched, handleSubmit, setFieldTouched, setFieldValue, values }) => {
					return (
						<>
							<Form
								layout='vertical'
								hideRequiredMark
							>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item label="Name [IS]" name='name.is'>
											<Input
												name='name.is'
												value={values.name.is}
												placeholder="Name [IS]"
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Name [EN]" name='name.en'>
											<Input
												name='name.en'
												value={values.name.en}
												placeholder="Name [EN]"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item label="Description [IS]" name='description.is'>
											<Input
												name='description.is'
												value={values.description.is}
												placeholder="Name [IS]"
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Description [EN]" name='description.en'>
											<Input
												name='description.en'
												value={values.description.en}
												placeholder="Description [EN]"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={8}>
										<Form.Item label={`Discount percentage`} name={`discountPercentage`}>
											<Input
												type='number'
												name={`discountPercentage`}
												placeholder={`Discount percentage`}
												suffix={'%'}
											/>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label="Is pickup" name='isPickup'>
											<Checkbox
												name="isPickup"
											>
												Is pickup allowed
											</Checkbox>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label="Is delivery" name='isDelivery'>
											<Checkbox
												name="isDelivery"
											>
												Is delivery allowed
											</Checkbox>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={24}>
										<DaysInput
											errors={errors}
											values={values}
											setFieldValue={setFieldValue}
										/>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Form.Item label="What type of items" name='discountType' validate={errors.discountType && touched.discountType}>
											<Radio.Group onClick={() => setFieldValue('allowedItems', [])} name='discountType' value={values.discountType}>
												<Radio.Button value={"Pizza"}>Menu Pizzas</Radio.Button>
												<Radio.Button value={"MenuItem"}>Menu Items</Radio.Button>
											</Radio.Group>
										</Form.Item>
									</Col>
								</Row>
								{values.discountType &&
									<Row gutter={[16, 16]}>
										<Col span={24}>
											<Form.Item label="Allow all items" name='isAllItems' validate={errors.isAllItems && touched.isAllItems}>
												<Radio.Group name='isAllItems' value={values.isAllItems}>
													<Radio.Button value={true}>{values.discountType === 'Pizza' ? 'All menu pizzas' : 'All menu items'}</Radio.Button>
													<Radio.Button value={false}>{values.discountType === 'Pizza' ? 'Specific menu pizzas' : 'Specific menu items'}</Radio.Button>
												</Radio.Group>
											</Form.Item>
										</Col>
									</Row>
								}
								{!values.isAllItems &&
									<>

										<Row gutter={[16, 16]}>
											<Col span={24}>
												{values.discountType === 'Pizza' && <PizzaSelect onChange={(value) => setFieldValue('allowedItems', value)} value={values.allowedItems}/>}
												{values.discountType === 'MenuItem' && <MenuItemSelect onChange={(value) => setFieldValue('allowedItems', value)} value={values.allowedItems}/>}
												{(values.discountType !== null && errors.allowedItems && touched.allowedItems) && <Typography.Paragraph type='danger'>{errors.allowedItems}</Typography.Paragraph>}
											</Col>
										</Row>
									</>
								}
							</Form>
							<div
								style={{
									position: 'absolute',
									right: 0,
									bottom: 0,
									width: '100%',
									borderTop: '1px solid #e9e9e9',
									padding: '10px 16px',
									background: '#fff',
									textAlign: 'right',
								}}
								>
								<div style={{
									display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
								}}>
									<div>
										{discount &&
											<Button onClick={handleDelete} type='danger'>
												Delete discount
											</Button>
										}
									</div>
									<div>
										<Button onClick={onClose} style={{ marginRight: 8 }}>
											Cancel
										</Button>
										<Button onClick={handleSubmit} type="primary">
											Save
										</Button>
									</div>
								</div>
							</div>
						</>	
					)
				}}
			</Formik>
		</Drawer>
	)
}


function PizzaSelect ({ onChange, value }) {
	const { data, loading } = useQuery(INDEX_PIZZAS, { variables: { first: 100, after: 0 }, fetchPolicy: 'network-only' });
	if (loading || !data) {
		return <LoadingOutlined spin/>
	}

	return (
		<Select
			mode="multiple"
			style={{ width: '100%' }}
			placeholder="Please select"
			value={value}
			onChange={onChange}
		>
			{data.adminIndexPizzas.edges.map(({ node }, index) => {
				return <Option key={index} value={node.id}>{node.name.en}</Option>
			})}
		</Select>
	)
}

function MenuItemSelect ({ onChange, value }) {
	const [groupedMenuItems, setGroupedMenuItems] = useState([]);
	const { data, loading } = useQuery(INDEX_MENU_ITEMS, { variables: { first: 100, after: 0 }, fetchPolicy: 'network-only' });
	
	useEffect(() => {
		if (data && !loading) {
			setGroupedMenuItems(groupMenuItems(data.adminIndexMenuItems.edges))
		}
	}, [loading, data])

	if (loading || !data) {
		return <LoadingOutlined spin/>
	}

	return (
		<Select
			mode="multiple"
			style={{ width: '100%' }}
			placeholder="Please select"
			value={value}
			onChange={onChange}
		>
			{Object.keys(groupedMenuItems).map((item, index) => {
				return (
					<OptGroup label={item} key={item}>
						{groupedMenuItems[item].menuItems.map((menuItem, index) => (
							<Option key={menuItem.id} value={menuItem.id}>{menuItem.name.en} - {menuItem.price}</Option>
						))}
					</OptGroup>
				)
			})}
		</Select>
	)
}



function DaysInput ({ errors, values, setFieldValue }) {
	const [indeterminate, setIndeterminate] = useState(values.discountValidDays.length !== DAYS_OPTIONS.length);
	const [checkAll, setCheckAll] = useState(values.discountValidDays.length === DAYS_OPTIONS.length);

	function onChange (value) {
		setIndeterminate(!!value && value.length < DAYS_OPTIONS.length);
		setCheckAll(value.length === DAYS_OPTIONS.length);
	}

	function onCheckAllChange (e) {
		setFieldValue('discountValidDays', e.target.checked ? DAYS : [])
		setIndeterminate(false);
		setCheckAll(e.target.checked);
	}

	return (
		<Row>
			<Col span={18}>
				<Typography.Paragraph>What days is the discount valid?</Typography.Paragraph>
				{errors.discountValidDays && <Typography.Paragraph type='danger'>You need to add at least one item</Typography.Paragraph>}
			</Col>
			<Col span={6} >
				<Checkbox
					indeterminate={indeterminate}
					onChange={onCheckAllChange}
					checked={checkAll}
				>
					Select all
				</Checkbox>
			</Col>
			<Row>
				<Checkbox.Group options={DAYS_OPTIONS} onChange={onChange} name='discountValidDays' />
			</Row>
		</Row>
	)
}