import gql from 'graphql-tag'

export const ADMIN_LOGIN_MUTATION = gql`
	mutation LoginEmployeeMutation($input: LoginEmployeeInputType!) {
		adminLoginEmployee(loginEmployeeInput: $input) {
			token
		}
	}
`

export const ADMIN_UPDATE_BRANCH = gql`
	mutation UpdateBranchMutation($id: ID!, $input: AdminEditBranchInputType!) {
		adminUpdateBranch(id: $id, editBranchInput: $input) {
			id
		}
	}
`

export const ADMIN_CREATE_BRANCH = gql`
	mutation CreateBranchMutation($input: AdminCreateBranchInputType!) {
		adminCreateBranch(createBranchInput: $input) {
			id
		}
	}
`

export const ADMIN_UPDATE_BRANCH_TIME = gql`
	mutation adminUpdateBranchTime($id: ID!, $input: AdminUpdateBranchTimesInputType!) {
		adminUpdateBranchTime(id: $id, updateBranchTimesInput: $input) {
			message
		}
	}
`

export const ADMIN_DELETE_BRANCH = gql`
	mutation DeleteBranchMutation($id: ID!) {
		deleteBranch(id: $id) {
			message
		}
	}
`

export const ADMIN_CREATE_DISCOUNT = gql`
	mutation CreateDiscountMutation($input: CreateDiscountInputType!) {
		adminCreateDiscount(createDiscountInput: $input) {
			id
		}
	}
`

export const ADMIN_UPDATE_DISCOUNT = gql`
	mutation UpdateDiscountMutation($id: ID!, $input: EditDiscountInputType!) {
		adminUpdateDiscount(id: $id, editDiscountInput: $input) {
			id
		}
	}
`

export const ADMIN_DELETE_DISCOUNT = gql`
	mutation DeleteDiscountMutation($id: ID!) {
		adminDeleteDiscount(id: $id) {
			message
		}
	}
`

export const ADMIN_UPDATE_EMPLOYEE= gql`
	mutation UpdateEmployeeMutation($id: ID!, $input: UpdateEmployeeInputType!) {
		adminUpdateEmployee(id: $id, editEmployeeInput: $input) {
			id
		}
	}
`

export const ADMIN_CREATE_EMPLOYEE = gql`
	mutation CreateEmployeeMutation($input: CreateEmployeeInputType!) {
		adminCreateEmployee(createEmployeeInput: $input) {
			id
		}
	}
`

export const ADMIN_DELETE_EMPLOYEE = gql`
	mutation DeleteEmployeeMutation($id: ID!) {
		adminDeleteEmployee(id: $id) {
			message
		}
	}
`

export const ADMIN_GENERATE_SIGNATURE = gql`
	mutation adminGenerateSignature {
		adminGenerateSignature {
			signature
			timestamp
			api_key
			public_id
		}
	}
`

export const ADMIN_UPDATE_PIZZA = gql`
	mutation UpdatePizzaMutation($id: ID!, $input: AdminUpdatePizzaInputType!) {
		adminUpdatePizza(id: $id, editPizzaInput: $input) {
			id
		}
	}
`

export const ADMIN_CREATE_PIZZA = gql`
	mutation CreatePizzaMutation($input: AdminCreatePizzaInputType!) {
		adminCreatePizza(createPizzaInput: $input) {
			id
		}
	}
`

export const ADMIN_DELETE_PIZZA = gql`
	mutation DeletePizzaMutation($id: ID!) {
		adminDeletePizza(id: $id) {
			message
		}
	}
`

export const ADMIN_CREATE_TOPPING = gql`
	mutation CreateToppingMutation($input: AdminCreateToppingInputType!) {
		adminCreateTopping(createToppingInput: $input) {
			id
		}
	}
`


export const ADMIN_UPDATE_TOPPING = gql`
	mutation UpdateToppingMutation($id: ID!, $input: AdminEditToppingInputType!) {
		adminUpdateTopping(id: $id, editToppingInput: $input) {
			id
		}
	}
`

export const ADMIN_DELETE_TOPPING = gql`
	mutation DeleteToppingMutation ($id: ID!) {
		adminDeleteTopping(id: $id) {
			message
		}
	}
`

export const ADMIN_CREATE_TOPPING_CATEGORY = gql`
	mutation CreateToppingCategoryMutation ($input: AdminToppingCategoryInputType!) {
		adminCreateToppingCategory(createToppingCategoryInput: $input) {
			id
		}
	}
`

export const ADMIN_UPDATE_TOPPING_CATEGORY = gql`
	mutation UpdateToppingCategoryMutation ($id: ID!, $input: AdminToppingCategoryInputType!) {
		adminUpdateToppingCategory(id: $id, editToppingCategoryInput: $input) {
			id
		}
	}
`

export const ADMIN_DELETE_TOPPING_CATEGORY = gql`
	mutation DeleteToppingCategoryMutation ($id: ID!) {
		adminDeleteToppingCategory(id: $id) {
			message
		}
	}
`

export const ADMIN_UPDATE_MENU_CATEGORY = gql`
	mutation UpdateMenuCategoryMutation ($id: ID!, $input: AdminEditMenuCategoryInputType!) {
		adminUpdateMenuCategory(id: $id, editMenuCategoryInput: $input) {
			id
		}
	}
`

export const ADMIN_CREATE_MENU_CATEGORY = gql`
	mutation CreateMenuCategoryMutation ($input: AdminCreateMenuCategoryInputType!) {
		adminCreateMenuCategory(createMenuCategoryInput: $input) {
			id
		}
	}
`

export const ADMIN_DELETE_MENU_CATEGORY = gql`
	mutation DeleteMenuCategoryMutation ($id: ID!) {
		adminDeleteMenuCategory(id: $id) {
			message
		}
	}
`

export const ADMIN_UPDATE_MENU_ITEM = gql`
	mutation UpdateMenuItemMutation ($id: ID!, $input: AdminEditMenuItemInputType!) {
		adminUpdateMenuItem(id: $id, editMenuItemInput: $input) {
			id
		}
	}
`

export const ADMIN_CREATE_MENU_ITEM = gql`
	mutation CreateMenuItemMutation ($input: AdminCreateMenuItemInputType!) {
		adminCreateMenuItem(createMenuItemInput: $input) {
			id
		}
	}
`

export const ADMIN_DELETE_MENU_ITEM = gql`
	mutation DeleteMenuItemMutation ($id: ID!) {
		adminDeleteMenuItem(id: $id) {
			message
		}
	}
` 

export const ADMIN_CREATE_OFFER = gql`
	mutation AdminCreateOffer ($input: AdminCreateOfferInputType!) {
		adminCreateOffer(createOfferInput: $input) {
			id
		}
	}
`

export const ADMIN_UPDATE_OFFER = gql`
	mutation AdminUpdateOffer ($id: ID!, $input: AdminEditOfferInputType!) {
		adminUpdateOffer(id: $id, editOfferInput: $input) {
			id
		}
	}
`

export const ADMIN_DELETE_OFFER = gql`
	mutation AdminDeleteOffer ($id: ID!) {
		adminDeleteOffer(id: $id) {
			message
		}
	}
`

export const ADMIN_UPDATE_CASTELLO_SETTINGS = gql`
	mutation AdminUpdateCastelloSettings ($input: EditCastelloSettingsInputType!) {
		adminUpdateCastelloSettings(editCastelloSettingsInput: $input) {
			message
		}
	}
`

export const ADMIN_UPDATE_USER = gql`
	mutation adminUpdateUser ($id: ID!, $input: AdminUserInputType!) {
		adminUpdateUser(id: $id, userInputType: $input) {
			message
		}
	}
`

export const ADMIN_MARK_AS_PRINTED = gql`
	mutation adminMarkOrdersPrinted ($orders: [ID]) {
		adminMarkOrdersPrinted(orders: $orders) {
			message
		}
	}
`

export const ADMIN_VOID_ORDER = gql`
	mutation adminVoidOrder ($order: ID!) {
		adminVoidOrder(order: $order) {
			message
		}
	}
`

export const ADMIN_UPDATE_ORDER_STATUS = gql`
	mutation adminUpdateOrderStatus ($order: ID!, $status: String!) {
		adminUpdateOrderStatus(order: $order, status: $status) {
			message
		}
	}
`

export const ADMIN_DELETE_ORDER = gql`
	mutation adminDeleteOrder ($order: ID!) {
		adminDeleteOrder(order: $order) {
			message
		}
	}
`