import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks';

// UI
import { Row, Col, Card, DatePicker, Table, Tag, Typography, Input, message, Button, Modal, Popover, Divider } from 'antd';

// Graphql
import { ADMIN_INDEX_ORDERS, INDEX_ORDER_DATA } from '../../../graphql/queries'
import { ADMIN_VOID_ORDER, ADMIN_DELETE_ORDER, ADMIN_UPDATE_ORDER_STATUS } from '../../../graphql/mutations'
import { displayGraphqlErrors } from '../../../graphql/utils'

// Util
import moment from 'moment';
import _ from 'lodash';
import { isAdmin } from '../../../services/token';

const PAGE_SIZE = 25;
const { Column, ColumnGroup } = Table;
const { Search } = Input;

const statuses = {
    'due': {
        color: 'volcano',
        label: 'óstaðfest'
    },
    'paid': {
        color: 'lime',
        label: 'greitt'
    },
    'in progress': {
        color: 'cyan',
        label: 'i vinnslu'
    },
    'ready': {
        color: 'green',
        label: 'tilbúin'
    },
    'delivered': {
        color: '#d8d8d8',
        label: 'afhent'
    },
    'cancelled': {
        color: '#d8d8d8',
        label: 'hætt við'
    }
}


export default function Data() {
	const [dates, setDates] = useState(null)
	const [dateValue, setDateValue] = useState(null)
	const { data: orderData, refetch: orderRefetch } = useQuery(INDEX_ORDER_DATA, dates && { variables: { dates: { from: dates[0], to: dates[1] } } });
	const [after, setAfter] = useState(0);
	const [voidModalVisible, setVoidModalVisible] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [currentVoidOrderId, setCurrentVoidOrderID] = useState(null);
	const [currentDeleteOrderId, setCurrentDeleteOrderId] = useState(null);
	const [phone, setPhone] = useState(null);
	const [orderNumber, setOrderNumber] = useState(null);

	const { data, loading, refetch } = useQuery(
			ADMIN_INDEX_ORDERS, {
			variables: dates ? {
					first: PAGE_SIZE,
					after,
					phone,
					orderNumber,
					dates: { from: dates[0], to: dates[1] },
			} : {
				first: PAGE_SIZE,
				after,
				phone,
				orderNumber,
			},
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'network-only',
	});
	const [adminVoidOrder] = useMutation(ADMIN_VOID_ORDER)
	const [adminDeleteOrder] = useMutation(ADMIN_DELETE_ORDER)
	const [adminUpdateOrderStatus] = useMutation(ADMIN_UPDATE_ORDER_STATUS);

	function handleTableChange(pagination, filters, sorter) {
			const currentPage = pagination.current - 1;
			setAfter(currentPage * PAGE_SIZE);
			refetch();
	};

	async function handleOrderStatusChange(order, status) {
			try {
					const { data } = await adminUpdateOrderStatus({ variables: { order, status } });
					message.info(data.adminUpdateOrderStatus.message)
					refetch();
			} catch (err) {
					displayGraphqlErrors(err)
			}
	}

	async function voidOrder() {
			try {
					const { data } = await adminVoidOrder({ variables: { order: currentVoidOrderId } });
					message.info(data.adminVoidOrder.message)
					refetch();
			} catch (err) {
					displayGraphqlErrors(err)
			} finally {
					closeVoidOrderModal();
			}
	}

	function openVoidOrderModal(order) {
			setVoidModalVisible(true);
			setCurrentVoidOrderID(order);
	}

	function closeVoidOrderModal() {
			setVoidModalVisible(false);
			setCurrentVoidOrderID(null);
	}

	function openDeleteOrderModal(order) {
			setDeleteModalVisible(true);
			setCurrentDeleteOrderId(order);
	}

	function closeDeleteOrderModal() {
			setDeleteModalVisible(false);
			setCurrentDeleteOrderId(null);
	}

	async function deleteOrder() {
			try {
					const { data } = await adminDeleteOrder({ variables: { order: currentDeleteOrderId } });
					message.info(data.adminDeleteOrder.message)
					refetch();
			} catch (err) {
					displayGraphqlErrors(err)
			} finally {
					closeDeleteOrderModal();
			}
	}

	useEffect(() => {
			refetch();
	}, [phone, refetch])

	useEffect(() => {
			refetch();
	}, [orderNumber, refetch])

	const getToppingsRender = (toppings) => {
			if (!toppings) return null;
			return toppings.map(({ topping: { en }, amount }) => (
					<Tag>
							{en}
							{amount > 1 ? `x ${amount}` : ''}
					</Tag>
			))
	}

	const renderToppings = (toppingsSelectionSide1, toppingsSelectionSide2, isDivided) => {
			let FirstToppings = getToppingsRender(toppingsSelectionSide1)
			let SecondToppings = getToppingsRender(toppingsSelectionSide2);
			return (
					<>
							<Typography.Text>
									<strong>{(SecondToppings && isDivided) ? 'First half:' : ''}</strong> {FirstToppings}
							</Typography.Text>
							{(SecondToppings && isDivided) &&
									<>
											<br />
											<Typography.Text>
													<strong>Second half: </strong> {SecondToppings}
											</Typography.Text>
									</>
							}
					</>
			)
	}

	const renderMenuItemTable = (menuItems, isMenuItemOffer) => {
			if (!menuItems) return null;
			return (
					<>
							<Divider orientation="left" plain={isMenuItemOffer}>{'Menu Items'}</Divider>
							<Table
									bordered
									dataSource={menuItems}
									rowKey={record => record.name.en}
									pagination={false}
							>
									<Column
											title="Name"
											key="name"
											render={(text, row) => {
													return <span>{row.name.en}</span>
											}}
									/>
							</Table>
					</>
			)
	}

	const renderPizzaItemTable = (pizzas, isPizzaOffer) => {
			if (!pizzas) return null;
			return (
					<>
							<Divider orientation="left" plain={isPizzaOffer}>{'Pizzas'}</Divider>
							<Table
									bordered
									dataSource={pizzas}
									rowKey={record => record.name.en}
									pagination={false}
							>
									<Column
											title="Size"
											key="size"
											render={(text, row) => {
													return <span><strong>{row.size.toUpperCase()}</strong></span>
											}}
									/>
									<Column
											title="Name"
											key="name"
											render={(text, row) => {
													return (
															<>
																	<span><strong>{row.pizzaName2?.en ? 'First half: ' : ''}</strong>{row.name ? row.name.en : 'Custom pizza'}</span>
																	<br />
																	{row.pizzaName2?.en && <span><strong>Second half: </strong>{row.pizzaName2 ? row.pizzaName2.en : ''}</span>}
															</>

													)
											}}
									/>
									<Column
											title="Toppings"
											key="toppings"
											render={(text, row) => {
													if (isPizzaOffer) {
															return renderToppings(row.toppings, row.toppings2, row.isDivided);
													} else {
															return renderToppings(row.toppingsSelectionSide1, row.toppingsSelectionSide2, row.isDivided);
													}
											}}
									/>
							</Table>
					</>
			)
	}

	const renderOfferItemTable = (offers) => {
			if (!offers) return null;
			return offers.map((offer) => {
					const { name, details } = offer;
					var grouped = _.mapValues(_.groupBy(details, 'type'), clist => clist.map(cartItem => _.omit(cartItem, 'type')));
					return (
							<>
									<Divider orientation="left">Offer: {name.en}</Divider>
									{renderPizzaItemTable(grouped['Pizza'], true)}
									{renderMenuItemTable(grouped['MenuItem'], true)}
							</>
					)
			})
	}

	const renderDeliveryInfo = (deliveryMethod, deliveryAddress) => {
			if (deliveryMethod === 'delivery') {
					const { postalCode, city, address, message } = deliveryAddress;
					return (
							<>
									<Divider orientation="left">Delivery Information</Divider>
									<Typography.Text>{`${address}, ${postalCode} ${city}`}</Typography.Text>
									<br />
									{!!message &&
											<Typography.Text>Message: {message}</Typography.Text>
									}
							</>
					)
			}
			return null;
	}

	const expandedRowRender = (record) => {
			const { cart, voided, id, deliveryMethod, deliveryAddress } = record.node;
			var grouped = _.mapValues(_.groupBy(cart, 'type'), clist => clist.map(cartItem => _.omit(cartItem, 'type')));
			const isUserAdmin = isAdmin();
			return (
					<>
							{renderDeliveryInfo(deliveryMethod, deliveryAddress)}
							<Divider orientation="left">Cart</Divider>
							{renderPizzaItemTable(grouped['Pizza'], false)}
							{renderMenuItemTable(grouped['MenuItem'])}
							{renderOfferItemTable(grouped['Offer'])}
							{isUserAdmin &&
									<>
											{voided ?
													<Button onClick={() => openDeleteOrderModal(id)} type='primary'>DELETE ORDER</Button>
													:
													<Button onClick={() => openVoidOrderModal(id)} type='primary'>Void Order</Button>
											}
									</>
							}
					</>
			)
	}

	function onChange(date, dateString) {
		setDates(dateString);
		setDateValue(date);
	}

	useEffect(() => {		
		orderRefetch();
		refetch();
	}, [dates, refetch, orderRefetch])

	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'row'}}>
				<Col span={6}>
					<Typography.Title level={3}>Dagsetningar</Typography.Title>
					<DatePicker.RangePicker onChange={onChange} value={dateValue} />
				</Col>
				{orderData && orderData.adminOrderData.map(({ branch, totalOrderPrice, totalOrders, paymentMethodsSummary, deliveryMethodsSummary }) => (
					<div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
						<Typography.Title level={3}>{branch.name}</Typography.Title>
						<Row gutter={16}>
							<Col span={16}>
								<Card>
									<Col>
										<Typography.Paragraph strong>Total</Typography.Paragraph>
										<Typography.Paragraph>{totalOrderPrice}</Typography.Paragraph>
										<Typography.Paragraph strong>Order amount</Typography.Paragraph>
										<Typography.Paragraph>{totalOrders}</Typography.Paragraph>
										<Divider orientation="left">By payment methods</Divider>
										{paymentMethodsSummary.map(({ paymentMethod, totalPrice, totalOrders }) => (
											<>	
												<Typography.Paragraph strong>Total for {paymentMethod}</Typography.Paragraph>
												<Typography.Paragraph>{totalPrice}</Typography.Paragraph>
												<Typography.Paragraph strong>Order amount</Typography.Paragraph>
												<Typography.Paragraph>{totalOrders}</Typography.Paragraph>
											</>
										))}
										<Divider orientation="left">By delivery methods</Divider>
										{deliveryMethodsSummary.map(({ deliveryMethod, totalPrice, totalOrders }) => (
											<>	
												<Typography.Paragraph strong>Total for {deliveryMethod}</Typography.Paragraph>
												<Typography.Paragraph>{totalPrice}</Typography.Paragraph>
												<Typography.Paragraph strong>Order amount</Typography.Paragraph>
												<Typography.Paragraph>{totalOrders}</Typography.Paragraph>
											</>
										))}
									</Col>
								</Card>
							</Col>
						</Row>
					</div>
				))}
			</div>
			<>
            <div className="toolbar" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <Search
                    placeholder="Input phone number"
                    onSearch={value => setPhone(value)}
                    style={{ width: 200 }}
                />
                <Search
                    placeholder="Input order number"
                    onSearch={value => setOrderNumber(value)}
                    style={{ width: 200 }}
                />
            </div>
            <Modal
                title="Void Order"
                visible={voidModalVisible}
                onOk={voidOrder}
                onCancel={closeVoidOrderModal}
            >
                <p>Are you sure you want to void this order this action cannot be reversed</p>
            </Modal>
            <Modal
                title="Delete Order"
                visible={deleteModalVisible}
                onOk={deleteOrder}
                onCancel={closeDeleteOrderModal}
            >
                <p>Are you sure you want to delete this order this action cannot be reversed</p>
            </Modal>
            <Table
                expandable={{
                    expandedRowRender: record => expandedRowRender(record)
                }}
                bordered
                rowKey={record => record.node.id}
                dataSource={data ? data.adminIndexOrders.edges : []}
                pagination={{
                    showSizeChanger: false,
                    pageSize: PAGE_SIZE,
                    defaultCurrent: 1,
                    total: data && data.adminIndexOrders.totalCount
                }}
                loading={loading}
                onChange={handleTableChange}
            >
                <Column
                    title="Order number"
                    key="orderNumber"
                    render={(text, row) => {
                        return (
                            <span>
                                {row.node?.orderNumber}
                            </span>
                        )
                    }
                    }
                />
                <Column
                    title="Date"
                    key="createdAt"
                    render={(text, row) => {
                        return (
                            <span>
                                {moment.unix(row.node.createdAt / 1000).format('MM/DD/YYYY HH:mm:ss')}
                            </span>
                        )
                    }
                    }
                />
                <ColumnGroup
                    title='Customer'
                    key="customer"
                >
                    <Column
                        title="Name"
                        key="name"
                        render={(text, row) => {
                            return (
                                <span>
                                    {row.node.user.name}
                                </span>
                            )
                        }
                        }
                    />
                    <Column
                        title="Phone"
                        key="phone"
                        render={(text, row) => {
                            return (
                                <span>
                                    {row.node.user.phone}
                                </span>
                            )
                        }
                        }
                    />
                </ColumnGroup>
                <Column
                    title="Message"
                    key="message"
                    render={(text, row) => {
                        return (
                            <span>
                                {row.node.message}
                            </span>
                        )
                    }
                    }
                />
                <Column
                    title="Delivery Method"
                    key="deliveryMethod"
                    render={(text, row) => {
                        return (
                            <Tag>
                                {row.node.deliveryMethod.toUpperCase()}
                            </Tag>
                        )
                    }
                    }
                />
                <ColumnGroup
                    title='Payment'
                    key="payment"
                >
                    <Column
                        title="Total price"
                        key="price"
                        render={(text, row) => {
                            return (
                                <Tag>
                                    {row.node.totalPrice} ISK
                                </Tag>
                            )
                        }
                        }
                    />
                    <Column
                        title="Payment method"
                        key="paymentMethod"
                        render={(text, row) => {
                            return (
                                <Tag>
                                    {row.node.paymentMethod}
                                </Tag>
                            )
                        }
                        }
                    />
                    <Column
                        title="Payment status"
                        key="status"
                        render={(text, row) => {
                            return (
                                <Tag>
                                    {row.node.status}
                                </Tag>
                            )
                        }
                        }
                    />
                </ColumnGroup>
                <Column
                    width={20}
                    title=""
                    key="tools"
                    render={(text, row) => (
                        <Popover content={
                            Object.keys(statuses).map(status => <Button onClick={e => handleOrderStatusChange(row.node.id, status)}>{statuses[status].label}</Button>)
                        } title="Aðgerðir" trigger="click">
                            <Button style={{ marginRight: '10px' }} type="dashed" size='small'>Uppfæra</Button>
                        </Popover>
                    )}
                />
            </Table>
        </>
		</>
	);
}
