import { message } from "antd";

export const mapGraphqlErrorsToArray = (error) => {
	return error.graphQLErrors.map(({ message }, i) => {
		return message
	});
}

export const displayGraphqlErrors = (error) => {
	if (error && error.graphQLErrors) {
		error.graphQLErrors.map(({ message }, i) => {
			return displayGraphqlError(message);
		});
	}
}

export const displayGraphqlError = (error) => {
	message.error(error)
}

export const mapEdgesToNodes = (edges) => {
	return edges.map((node) => node);
}