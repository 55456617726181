import React from 'react'
import { Row, Col, Typography, Card, Button, message } from 'antd'
import { displayGraphqlErrors } from '../../../../graphql/utils'
import { Formik, Field as FormikField, getIn } from 'formik'
import { Form, Input, TimePicker, InputNumber, Select, Checkbox } from 'formik-antd'
import { useMutation } from '@apollo/react-hooks'
import { ADMIN_UPDATE_BRANCH, ADMIN_CREATE_BRANCH } from '../../../../graphql/mutations'
import * as Yup from 'yup'
import moment from 'moment';
import { DAYS_TO_STRING } from '../../../../utils/days';
import { ZIP_CODE_REGEX } from '../../../../utils/regex';
import zip_codes from '../../../../utils/zip_codes'

const { Option } = Select;
const format = 'HH:mm';

const BranchSchema = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	phone: Yup.string().required('Phone number is required'),
	minutesBeforeOnlineSalesClose: Yup.number().required('Minutes before online sales close is required'),
	address: Yup.object().shape({
		street: Yup.string().required('Street is required'),
		postalCode: Yup.string().required('Postal code is required'),
		city: Yup.string().required('City is required')
	}),
	homeDeliveryPostalCodes: Yup.array().of(Yup.string().matches(ZIP_CODE_REGEX, 'Postal code is not in correct format')),
	openingHours: Yup.object().shape({
		mon: Yup.object().shape({
			from: Yup.string().test('is-closed', 'You must specify from', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			to: Yup.string().test('is-closed', 'You must specify to', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			isClosed: Yup.boolean().required('Is closed is required')
		}),
		tue: Yup.object().shape({
			from: Yup.string().test('is-closed', 'You must specify from', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			to: Yup.string().test('is-closed', 'You must specify to', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			isClosed: Yup.boolean().required('Is closed is required')
		}),
		wed: Yup.object().shape({
			from: Yup.string().test('is-closed', 'You must specify from', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			to: Yup.string().test('is-closed', 'You must specify to', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			isClosed: Yup.boolean().required('Is closed is required')
		}),
		thu: Yup.object().shape({
			from: Yup.string().test('is-closed', 'You must specify from', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			to: Yup.string().test('is-closed', 'You must specify to', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			isClosed: Yup.boolean().required('Is closed is required')
		}),
		fri: Yup.object().shape({
			from: Yup.string().test('is-closed', 'You must specify from', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			to: Yup.string().test('is-closed', 'You must specify to', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			isClosed: Yup.boolean().required('Is closed is required')
		}),
		sat: Yup.object().shape({
			from: Yup.string().test('is-closed', 'You must specify from', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			to: Yup.string().test('is-closed', 'You must specify to', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			isClosed: Yup.boolean().required('Is closed is required')
		}),
		sun: Yup.object().shape({
			from: Yup.string().test('is-closed', 'You must specify from', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			to: Yup.string().test('is-closed', 'You must specify to', function (value) {
				return this.parent.isClosed || value !== null;
			}),
			isClosed: Yup.boolean().required('Is closed is required')
		})
	})
});

const defaultAddress = {
	street: '',
	postalCode: '',
	city: ''
}

const defaultOpeningHours = {
	mon: { from: '', to: '', isClosed: false },
	tue: { from: '', to: '', isClosed: false },
	wed: { from: '', to: '', isClosed: false },
	thu: { from: '', to: '', isClosed: false },
	fri: { from: '', to: '', isClosed: false },
	sat: { from: '', to: '', isClosed: false },
	sun: { from: '', to: '', isClosed: false },
}

export default function BranchDetails({ branch, onCreate }) {
	const [adminUpdateBranch] = useMutation(ADMIN_UPDATE_BRANCH)
	const [adminCreateBranch] = useMutation(ADMIN_CREATE_BRANCH)
	// const [deleteBranch] = useMutation(ADMIN_DELETE_BRANCH)

	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				name: branch ? branch.name : '',
				phone: branch ? branch.phone : '',
				address: branch ? branch.address : defaultAddress,
				openingHours: branch ? branch.openingHours : defaultOpeningHours,
				minutesBeforeOnlineSalesClose: branch ? branch.minutesBeforeOnlineSalesClose : 0,
				homeDeliveryPostalCodes: branch ? branch.homeDeliveryPostalCodes : [],
			}}
			validationSchema={BranchSchema}
			onSubmit={async values => {
				try {
					if (branch && branch.id) {
						await adminUpdateBranch({ variables: { id: branch.id, input: values } });
						message.info('Branch updated successfully');
					} else {
						await adminCreateBranch({ variables: { input: values } })
						message.info('Branch created successfully');
						onCreate();
					}
				} catch (err) {
					displayGraphqlErrors(err)
				}
			}}
		>
			{({ errors, touched, handleSubmit, setFieldValue, setFieldTouched, values }) => {
				return (
					<>
						<Form
							layout='vertical'
							hideRequiredMark
						>
							<Row gutter={[32, 32]}>
								<Col span={12}>
									<Card title="General Information">
										<Form.Item label="Name" name='name' validate={errors.name && touched.name}>
											<Input
												name='name'
												value={values.name}
												placeholder="Name"
											/>
										</Form.Item>
										<Form.Item label="Phone" name='phone' validate={errors.phone && touched.phone}>
											<Input
												name='phone'
												value={values.phone}
												placeholder="Phone"
											/>
										</Form.Item>
										<Form.Item label="Online sales close" name='minutesBeforeOnlineSalesClose' validate={errors.name && touched.name}>
											<InputNumber
												style={{ verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
												name="minutesBeforeOnlineSalesClose"
												min={0}
												max={180}
											/>
											<div className="ant-input-group-addon" style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>minutes</div>
										</Form.Item>
										<Form.Item label="Valid delivery postal codes" name='homeDeliveryPostalCodes' validate={errors.homeDeliveryPostalCodes && touched.homeDeliveryPostalCodes}>
											<Select
												name='homeDeliveryPostalCodes'
												mode="multiple"
												placeholder="Please select"
												style={{ width: '100%' }}
												value={values.homeDeliveryPostalCodes}
											>
												{zip_codes.map((item, index) => {
													return <Option key={item.zip} value={item.zip}>{item.label}</Option>
												})}
											</Select>
										</Form.Item>
									</Card>
								</Col>
								<Col span={12}>
									<Card title="Address Information">
										<Form.Item
											label="Address street"
											name='address.street'
											validate={getIn(errors, 'address.street') && getIn(touched, 'address.street')}
										>
											<Input
												name='address.street'
												value={values.address.street}
												placeholder="Address street"
											/>
										</Form.Item>
										<Form.Item
											label="City"
											name='address.city'
											validate={getIn(errors, 'address.city') && getIn(touched, 'address.city')}
										>
											<Input
												name='address.city'
												value={values.address.city}
												placeholder="City"
											/>
										</Form.Item>
										<Form.Item
											label="Postal code"
											name='address.postalCode'
											validate={getIn(errors, 'address.postalCode') && getIn(touched, 'address.postalCode')}
										>
											<Input
												name='address.postalCode'
												value={values.address.postalCode}
												placeholder="Postal code"
											/>
										</Form.Item>
									</Card>
								</Col>
							</Row>
							<Row gutter={[32, 32]}>
								<Col span={12}>
									<Card title="Opening hours">
										{Object.keys(values.openingHours).map((key) => {
											return (
												<Row key={key}>
													<Col span={4}>
														<Typography.Text strong>{DAYS_TO_STRING[key]}</Typography.Text>
													</Col>
													<Col span={4}>
														<Form.Item name={`openingHours.${key}.isClosed`}>
															<Checkbox value={values.openingHours[key].isClosed} name={`openingHours.${key}.isClosed`}>
																Is closed
													</Checkbox>
														</Form.Item>
													</Col>
													{!values.openingHours[key].isClosed &&
														<>
															<Form.Item
																name={`openingHours.${key}.from`}
																validate={getIn(errors, `openingHours.${key}.from`) && getIn(touched, `openingHours.${key}.from`)}
															>
																<FormikField
																	name={`openingHours.${key}.from`}
																>
																	{({ field }) => (
																		<TimePicker
																			{...field}
																			allowClear={false}
																			name={`openingHours.${key}.from`}
																			value={moment(values.openingHours[key].from, format)}
																			onChange={value => setFieldValue(`openingHours.${key}.from`, moment(value).format('HHmm'))}
																			onBlur={() => setFieldTouched(`openingHours.${key}.from`, true)}
																			format={format}
																		/>
																	)}
																</FormikField>
															</Form.Item>
															<div className="ant-input-group-addon" style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>to</div>
															<Form.Item
																name={`openingHours.${key}.to`}
																validate={getIn(errors, `openingHours.${key}.to`) && getIn(touched, `openingHours.${key}.to`)}
															>
																<FormikField
																	name={`openingHours.${key}.to`}
																>
																	{({ field }) => (
																		<TimePicker
																			{...field}
																			allowClear={false}
																			name={`openingHours.${key}.to`}
																			value={moment(values.openingHours[key].to, format)}
																			onChange={value => setFieldValue(`openingHours.${key}.to`, moment(value).format('HHmm'))}
																			onBlur={() => setFieldTouched(`openingHours.${key}.to`, true)}
																			format={format}
																		/>
																	)}
																</FormikField>
															</Form.Item>
														</>
													}
												</Row>
											)
										})}
									</Card>
								</Col>
							</Row>
							<Button type='primary' onClick={handleSubmit}>
								Save
							</Button>
						</Form>
					</>
				)
			}}
		</Formik>
	)
}
