export default [
  {
    zip: '101',
    city: 'Reykjavík',
    label: '101 Reykjavík'
  },
  {
    zip: '102',
    city: 'Reykjavík',
    label: '102 Reykjavík'
  },
  {
    zip: '103',
    city: 'Reykjavík',
    label: '103 Reykjavík'
  },
  {
    zip: '104',
    city: 'Reykjavík',
    label: '104 Reykjavík'
  },
  {
    zip: '105',
    city: 'Reykjavík',
    label: '105 Reykjavík'
  },
  {
    zip: '107',
    city: 'Reykjavík',
    label: '107 Reykjavík'
  },
  {
    zip: '108',
    city: 'Reykjavík',
    label: '108 Reykjavík'
  },
  {
    zip: '109',
    city: 'Reykjavík',
    label: '109 Reykjavík'
  },
  {
    zip: '110',
    city: 'Reykjavík',
    label: '110 Reykjavík'
  },
  {
    zip: '111',
    city: 'Reykjavík',
    label: '111 Reykjavík'
  },
  {
    zip: '112',
    city: 'Reykjavík',
    label: '112 Reykjavík'
  },
  {
    zip: '113',
    city: 'Reykjavík',
    label: '113 Reykjavík'
  },
  {
    zip: '116',
    city: 'Reykjavík',
    label: '116 Reykjavík'
  },
  {
    zip: '121',
    city: 'Reykjavík',
    label: '121 Reykjavík'
  },
  {
    zip: '123',
    city: 'Reykjavík',
    label: '123 Reykjavík'
  },
  {
    zip: '124',
    city: 'Reykjavík',
    label: '124 Reykjavík'
  },
  {
    zip: '125',
    city: 'Reykjavík',
    label: '125 Reykjavík'
  },
  {
    zip: '127',
    city: 'Reykjavík',
    label: '127 Reykjavík'
  },
  {
    zip: '128',
    city: 'Reykjavík',
    label: '128 Reykjavík'
  },
  {
    zip: '129',
    city: 'Reykjavík',
    label: '129 Reykjavík'
  },
  {
    zip: '130',
    city: 'Reykjavík',
    label: '130 Reykjavík'
  },
  {
    zip: '132',
    city: 'Reykjavík',
    label: '132 Reykjavík'
  },
  {
    zip: '150',
    city: 'Reykjavík',
    label: '150 Reykjavík'
  },
  {
    zip: '155',
    city: 'Reykjavík',
    label: '155 Reykjavík'
  },
  {
    zip: '170',
    city: 'Seltjarnarnes',
    label: '170 Seltjarnarnes'
  },
  {
    zip: '172',
    city: 'Seltjarnarnes',
    label: '172 Seltjarnarnes'
  },
  {
    zip: '190',
    city: 'Vogar',
    label: '190 Vogar'
  },
  {
    zip: '200',
    city: 'Kópavogur',
    label: '200 Kópavogur'
  },
  {
    zip: '201',
    city: 'Kópavogur',
    label: '201 Kópavogur'
  },
  {
    zip: '202',
    city: 'Kópavogur',
    label: '202 Kópavogur'
  },
  {
    zip: '203',
    city: 'Kópavogur',
    label: '203 Kópavogur'
  },
  {
    zip: '210',
    city: 'Garðabær',
    label: '210 Garðabær'
  },
  {
    zip: '212',
    city: 'Garðabær',
    label: '212 Garðabær'
  },
  {
    zip: '220',
    city: 'Hafnarfjörður',
    label: '220 Hafnarfjörður'
  },
  {
    zip: '221',
    city: 'Hafnarfjörður',
    label: '221 Hafnarfjörður'
  },
  {
    zip: '222',
    city: 'Hafnarfjörður',
    label: '222 Hafnarfjörður'
  },
  {
    zip: '225',
    city: 'Álftanes',
    label: '225 Álftanes'
  },
  {
    zip: '230',
    city: 'Reykjanesbær',
    label: '230 Reykjanesbær'
  },
  {
    zip: '232',
    city: 'Reykjanesbær',
    label: '232 Reykjanesbær'
  },
  {
    zip: '233',
    city: 'Reykjanesbær',
    label: '233 Reykjanesbær'
  },
  {
    zip: '235',
    city: 'Reykjanesbær',
    label: '235 Reykjanesbær'
  },
  {
    zip: '240',
    city: 'Grindavík',
    label: '240 Grindavík'
  },
  {
    zip: '245',
    city: 'Sandgerði',
    label: '245 Sandgerði'
  },
  {
    zip: '250',
    city: 'Garður',
    label: '250 Garður'
  },
  {
    zip: '260',
    city: 'Reykjanesbær',
    label: '260 Reykjanesbær'
  },
  {
    zip: '270',
    city: 'Mosfellsbær',
    label: '270 Mosfellsbær'
  },
  {
    zip: '271',
    city: 'Mosfellsbær',
    label: '271 Mosfellsbær'
  },
  {
    zip: '276',
    city: 'Mosfellsbær',
    label: '276 Mosfellsbær'
  },
  {
    zip: '300',
    city: 'Akranes',
    label: '300 Akranes'
  },
  {
    zip: '301',
    city: 'Akranes',
    label: '301 Akranes'
  },
  {
    zip: '302',
    city: 'Akranes',
    label: '302 Akranes'
  },
  {
    zip: '310',
    city: 'Borgarnes',
    label: '310 Borgarnes'
  },
  {
    zip: '311',
    city: 'Borgarnes',
    label: '311 Borgarnes'
  },
  {
    zip: '320',
    city: 'Reykholt',
    label: '320 Reykholt'
  },
  {
    zip: '340',
    city: 'Stykkishólmur',
    label: '340 Stykkishólmur'
  },
  {
    zip: '345',
    city: 'Flatey',
    label: '345 Flatey'
  },
  {
    zip: '350',
    city: 'Grundarfjörður',
    label: '350 Grundarfjörður'
  },
  {
    zip: '355',
    city: 'Ólafsvík',
    label: '355 Ólafsvík'
  },
  {
    zip: '356',
    city: 'Snæfellsbær',
    label: '356 Snæfellsbær'
  },
  {
    zip: '360',
    city: 'Hellissandur',
    label: '360 Hellissandur'
  },
  {
    zip: '370',
    city: 'Búðardalur',
    label: '370 Búðardalur'
  },
  {
    zip: '371',
    city: 'Búðardalur',
    label: '371 Búðardalur'
  },
  {
    zip: '380',
    city: 'Reykhólahreppur',
    label: '380 Reykhólahreppur'
  },
  {
    zip: '400',
    city: 'Ísafjörður',
    label: '400 Ísafjörður'
  },
  {
    zip: '401',
    city: 'Ísafjörður',
    label: '401 Ísafjörður'
  },
  {
    zip: '410',
    city: 'Hnífsdalur',
    label: '410 Hnífsdalur'
  },
  {
    zip: '415',
    city: 'Bolungarvík',
    label: '415 Bolungarvík'
  },
  {
    zip: '420',
    city: 'Súðavík',
    label: '420 Súðavík'
  },
  {
    zip: '425',
    city: 'Flateyri',
    label: '425 Flateyri'
  },
  {
    zip: '430',
    city: 'Suðureyri',
    label: '430 Suðureyri'
  },
  {
    zip: '450',
    city: 'Patreksfjörður',
    label: '450 Patreksfjörður'
  },
  {
    zip: '451',
    city: 'Patreksfjörður',
    label: '451 Patreksfjörður'
  },
  {
    zip: '460',
    city: 'Tálknafjörður',
    label: '460 Tálknafjörður'
  },
  {
    zip: '465',
    city: 'Bíldudalur',
    label: '465 Bíldudalur'
  },
  {
    zip: '470',
    city: 'Þingeyri',
    label: '470 Þingeyri'
  },
  {
    zip: '471',
    city: 'Þingeyri',
    label: '471 Þingeyri'
  },
  {
    zip: '500',
    city: 'Staður',
    label: '500 Staður'
  },
  {
    zip: '510',
    city: 'Hólmavík',
    label: '510 Hólmavík'
  },
  {
    zip: '512',
    city: 'Hólmavík',
    label: '512 Hólmavík'
  },
  {
    zip: '520',
    city: 'Drangsnes',
    label: '520 Drangsnes'
  },
  {
    zip: '524',
    city: 'Árneshreppur',
    label: '524 Árneshreppur'
  },
  {
    zip: '530',
    city: 'Hvammstangi',
    label: '530 Hvammstangi'
  },
  {
    zip: '531',
    city: 'Hvammstangi',
    label: '531 Hvammstangi'
  },
  {
    zip: '540',
    city: 'Blönduós',
    label: '540 Blönduós'
  },
  {
    zip: '541',
    city: 'Blönduós',
    label: '541 Blönduós'
  },
  {
    zip: '545',
    city: 'Skagaströnd',
    label: '545 Skagaströnd'
  },
  {
    zip: '550',
    city: 'Sauðárkrókur',
    label: '550 Sauðárkrókur'
  },
  {
    zip: '551',
    city: 'Sauðárkrókur',
    label: '551 Sauðárkrókur'
  },
  {
    zip: '560',
    city: 'Varmahlíð',
    label: '560 Varmahlíð'
  },
  {
    zip: '565',
    city: 'Hofsós',
    label: '565 Hofsós'
  },
  {
    zip: '566',
    city: 'Hofsós',
    label: '566 Hofsós'
  },
  {
    zip: '570',
    city: 'Fljót',
    label: '570 Fljót'
  },
  {
    zip: '580',
    city: 'Siglufjörður',
    label: '580 Siglufjörður'
  },
  {
    zip: '600',
    city: 'Akureyri',
    label: '600 Akureyri'
  },
  {
    zip: '601',
    city: 'Akureyri',
    label: '601 Akureyri'
  },
  {
    zip: '602',
    city: 'Akureyri',
    label: '602 Akureyri'
  },
  {
    zip: '603',
    city: 'Akureyri',
    label: '603 Akureyri'
  },
  {
    zip: '610',
    city: 'Grenivík',
    label: '610 Grenivík'
  },
  {
    zip: '611',
    city: 'Grímsey',
    label: '611 Grímsey'
  },
  {
    zip: '620',
    city: 'Dalvík',
    label: '620 Dalvík'
  },
  {
    zip: '621',
    city: 'Dalvík',
    label: '621 Dalvík'
  },
  {
    zip: '625',
    city: 'Ólafsfjörður',
    label: '625 Ólafsfjörður'
  },
  {
    zip: '630',
    city: 'Hrísey',
    label: '630 Hrísey'
  },
  {
    zip: '640',
    city: 'Húsavík',
    label: '640 Húsavík'
  },
  {
    zip: '641',
    city: 'Húsavík',
    label: '641 Húsavík'
  },
  {
    zip: '645',
    city: 'Fosshóll',
    label: '645 Fosshóll'
  },
  {
    zip: '650',
    city: 'Laugar',
    label: '650 Laugar'
  },
  {
    zip: '660',
    city: 'Mývatn',
    label: '660 Mývatn'
  },
  {
    zip: '670',
    city: 'Kópasker',
    label: '670 Kópasker'
  },
  {
    zip: '671',
    city: 'Kópaskeri',
    label: '671 Kópaskeri'
  },
  {
    zip: '675',
    city: 'Raufarhöfn',
    label: '675 Raufarhöfn'
  },
  {
    zip: '680',
    city: 'Þórshöfn',
    label: '680 Þórshöfn'
  },
  {
    zip: '681',
    city: 'Þórshöfn',
    label: '681 Þórshöfn'
  },
  {
    zip: '685',
    city: 'Bakkafjörður',
    label: '685 Bakkafjörður'
  },
  {
    zip: '690',
    city: 'Vopnafjörður',
    label: '690 Vopnafjörður'
  },
  {
    zip: '700',
    city: 'Fellabær',
    label: '700 Fellabær'
  },
  {
    zip: '701',
    city: 'Egilsstaðir',
    label: '701 Egilsstaðir'
  },
  {
    zip: '710',
    city: 'Seyðisfjörður',
    label: '710 Seyðisfjörður'
  },
  {
    zip: '715',
    city: 'Mjóifjörður',
    label: '715 Mjóifjörður'
  },
  {
    zip: '720',
    city: 'Borgarfjörður',
    label: '720 Borgarfjörður'
  },
  {
    zip: '730',
    city: 'Reyðarfjörður',
    label: '730 Reyðarfjörður'
  },
  {
    zip: '735',
    city: 'Eskifjörður',
    label: '735 Eskifjörður'
  },
  {
    zip: '740',
    city: 'Neskaupstaður',
    label: '740 Neskaupstaður'
  },
  {
    zip: '750',
    city: 'Fáskrúðsfjörður',
    label: '750 Fáskrúðsfjörður'
  },
  {
    zip: '755',
    city: 'Stöðvarfjörður',
    label: '755 Stöðvarfjörður'
  },
  {
    zip: '760',
    city: 'Breiðdalsvík',
    label: '760 Breiðdalsvík'
  },
  {
    zip: '765',
    city: 'Djúpivogur',
    label: '765 Djúpivogur'
  },
  {
    zip: '780',
    city: 'Höfn',
    label: '780 Höfn'
  },
  {
    zip: '781',
    city: 'Höfn',
    label: '781 Höfn'
  },
  {
    zip: '785',
    city: 'Öræfi',
    label: '785 Öræfi'
  },
  {
    zip: '800',
    city: 'Selfoss',
    label: '800 Selfoss'
  },
  {
    zip: '801',
    city: 'Selfoss',
    label: '801 Selfoss'
  },
  {
    zip: '802',
    city: 'Selfoss',
    label: '802 Selfoss'
  },
  {
    zip: '810',
    city: 'Hveragerði',
    label: '810 Hveragerði'
  },
  {
    zip: '815',
    city: 'Þorlákshöfn',
    label: '815 Þorlákshöfn'
  },
  {
    zip: '816',
    city: 'Ölfus',
    label: '816 Ölfus'
  },
  {
    zip: '820',
    city: 'Eyrarbakki',
    label: '820 Eyrarbakki'
  },
  {
    zip: '825',
    city: 'Stokkseyri',
    label: '825 Stokkseyri'
  },
  {
    zip: '840',
    city: 'Laugarvatn',
    label: '840 Laugarvatn'
  },
  {
    zip: '845',
    city: 'Flúðir',
    label: '845 Flúðir'
  },
  {
    zip: '850',
    city: 'Hella',
    label: '850 Hella'
  },
  {
    zip: '851',
    city: 'Hella',
    label: '851 Hella'
  },
  {
    zip: '860',
    city: 'Hvolsvöllur',
    label: '860 Hvolsvöllur'
  },
  {
    zip: '861',
    city: 'Hvolsvöllur',
    label: '861 Hvolsvöllur'
  },
  {
    zip: '870',
    city: 'Vík',
    label: '870 Vík'
  },
  {
    zip: '871',
    city: 'Vík',
    label: '871 Vík'
  },
  {
    zip: '880',
    city: 'Kirkjubæjarklaustur',
    label: '880 Kirkjubæjarklaustur'
  },
  {
    zip: '900',
    city: 'Vestmannaeyjar',
    label: '900 Vestmannaeyjar'
  },
  {
    zip: '902',
    city: 'Vestmannaeyjar',
    label: '902 Vestmannaeyjar'
  }
]
