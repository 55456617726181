import React, { useState } from 'react'
import { Form, Input, Radio, TimePicker, Checkbox } from 'formik-antd'
import { Formik, Field as FormikField, FieldArray, getIn } from 'formik';
import moment from 'moment';
import { DAYS, DAYS_OPTIONS } from '../../../../utils/days'
import {
	OfferValidationSchema,
	TIME_FORMAT,
	DEFAULT_PIZZA_ITEM,
	DEFAULT_SIDE_ITEM,
} from './constants'
import {
	Card,
	Button,
	Row,
	Col,
	Typography
} from 'antd'
import {
	PizzaSelect,
	PizzaSizeSelect,
	MenuItemSelect
} from './form-components'
import { ImageDropzone } from '../../../../components/form'

export default function OfferForm({ offer, onSubmit, handleDelete }) {
	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				name: offer ? offer.name : { en: '', is: '' },
				description: offer ? offer.description : { en: '', is: '' },
				price: offer ? offer.price : null,
				isPickup: offer ? offer.isPickup : true,
				isDelivery: offer ? offer.isDelivery : false,
				imageUrl: offer ? offer.imageUrl : null,
				active: offer ? offer.active : true,
				offerValidHours: offer ? (offer.offerValidHours.from === null && offer.offerValidHours.to === null ? null : offer.offerValidHours) : null,
				offerValidDays: offer ? offer.offerValidDays : DAYS,
				details: offer ? offer.details : [],
				category: offer ? offer.category : '',
			}}
			validationSchema={OfferValidationSchema}
			onSubmit={onSubmit}
		>
			{({ errors, touched, handleSubmit, setFieldValue, setFieldTouched, values }) => {
				return (
					<>
						<Form
							layout='vertical'
							hideRequiredMark
						>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
								<Typography.Title level={3}>{offer ? values.name.en : 'New offer'}</Typography.Title>
								<Button onClick={handleSubmit} type='primary'>
									Save
								</Button>
								{(offer && handleDelete) &&
									<Button onClick={handleDelete} type='primary'>
										Delete
									</Button>
								}
							</div>
							<Row gutter={[32, 32]}>
								<Col span={12}>
									<Card title="Offer Information">
										<OfferInformation values={values} setFieldValue={setFieldValue} />
									</Card>
								</Col>
								<Col span={12}>
									<Card title="Offer Constraints">
										<OfferConstraints errors={errors} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
									</Card>
								</Col>
							</Row>
							<Row gutter={[32, 32]}>
								<FieldArray
									name='details'
									render={({ remove, push }) => {
										return (
											<>
												<Col span={24}>
													<div style={{ display: 'flex', flexDirection: 'row' }}>
														<Typography.Title level={4}>Offer Items</Typography.Title>
														<Button style={{ marginLeft: '2rem' }} onClick={() => push(DEFAULT_PIZZA_ITEM)} type={'primary'}>
															Add pizza
														</Button>
														<Button style={{ marginLeft: '2rem' }} onClick={() => push(DEFAULT_SIDE_ITEM)} type={'primary'}>
															Add menu item
														</Button>
													</div>
												</Col>
												{values.details.map((detail, index) => {
													return (
														<Col span={12}>
															<Card title={`Offer item: ${index + 1} - ${detail.offerType === 'Pizza' ? 'Pizza' : 'Menu Item'}`} extra={<Button size='small' type='danger' onClick={() => remove(index)}>Remove</Button>}>
																<OfferDetails errors={errors} touched={touched} detail={detail} index={index} remove={remove} setFieldValue={setFieldValue} />
															</Card>
														</Col>
													)
												})}
											</>
										)
									}}
								/>
							</Row>
						</Form>
					</>
				)
			}}
		</Formik>
	)
}

function OfferDetails({ detail, index, setFieldValue, errors, touched }) {
	const [isAllPizzas, setAllPizzas] = useState(detail.allowedItems === null);

	function onAllPizzasChange(e) {
		setAllPizzas(e.target.value);
		if (e.target.value) {
			setFieldValue(`details.${index}.allowedItems`, null)
		} else {
			setFieldValue(`details.${index}.allowedItems`, [])
		}
	}

	return (
		<Col span={24}>
			<Col span={24}>
				{detail.offerType === 'MenuItem' &&
					<>
						<MenuItemSelect value={detail.allowedItems} onChange={(value) => setFieldValue(`details.${index}.allowedItems`, value)} />
					</>
				}
				{detail.offerType === 'Pizza' &&
					<>
						<PizzaSizeSelect name={`details.${index}.size`} value={detail.size} />
						<Form.Item label={'Is the pizza from the menu or not'} name={`details.${index}.isMenuPizza`}>
							<Radio.Group buttonStyle="solid" name={`details.${index}.isMenuPizza`} value={detail.isMenuPizza}>
								<Radio.Button
									value={true}
									onClick={() => setFieldValue(`details.${index}.toppingAmount`, null)}
								>
									Menu Pizza
								</Radio.Button>
								<Radio.Button
									value={false}
									onClick={() => {
										setFieldValue(`details.${index}.allowedItems`, [])
										setFieldValue(`details.${index}.isMenuPizza`, false)
									}
									}>
									Topping Amount
								</Radio.Button>
							</Radio.Group>
						</Form.Item>
						{detail.isMenuPizza &&
							<Row>
								<Form.Item label={'All pizzas or only selected pizzas'} name={`details.${index}.allowedItems`}>
									<Radio.Group onChange={onAllPizzasChange} buttonStyle="solid" name={`details.${index}.allowedItems`} value={isAllPizzas}>
										<Radio.Button value={true}>All pizzas</Radio.Button>
										<Radio.Button value={false}>Selected pizzas</Radio.Button>
									</Radio.Group>
								</Form.Item>
								{detail.allowedItems !== null &&
									<>
										<PizzaSelect onChange={(value) => setFieldValue(`details.${index}.allowedItems`, value)} value={detail.allowedItems} />
										{getIn(errors, `details.${index}.allowedItems`) && getIn(touched, `details.${index}.allowedItems`) && <Typography.Paragraph type='danger'>You need to add at least one item</Typography.Paragraph>}
									</>
								}
							</Row>
						}
						{!detail.isMenuPizza &&
							<Form.Item label={`How many toppings can the user add to the pizza`} name={`details.${index}.toppingAmount`}>
								<Input
									type='number'
									name={`details.${index}.toppingAmount`}
									placeholder={`Topping Amount`}
									suffix={'Toppings'}
								/>
							</Form.Item>
						}
					</>
				}
			</Col>
		</Col>
	)
}

function OfferConstraints({ errors, values, setFieldValue, setFieldTouched }) {
	const [indeterminate, setIndeterminate] = useState(values.offerValidDays.length !== DAYS_OPTIONS.length);
	const [checkAll, setCheckAll] = useState(values.offerValidDays.length === DAYS_OPTIONS.length);
	const [isAllDay, setIsAllDay] = useState(!values.offerValidHours?.from && !values.offerValidHours?.to)

	function onChange(value) {
		setFieldValue('offerValidDays', value)
		setIndeterminate(!!value && value.length < DAYS_OPTIONS.length);
		setCheckAll(value.length === DAYS_OPTIONS.length);
	}

	function onCheckAllChange(e) {
		setFieldValue('offerValidDays', e.target.checked ? DAYS : [])
		setIndeterminate(false);
		setCheckAll(e.target.checked);
	}

	function onTimeChange(e) {
		setIsAllDay(e.target.checked);
	}

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Form.Item label="Is pickup" name='isPickup'>
						<Checkbox
							name="isPickup"
						>
							Is pickup allowed
						</Checkbox>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Is delivery" name='isDelivery'>
						<Checkbox
							name="isDelivery"
						>
							Is delivery allowed
						</Checkbox>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={18}>
					<Typography.Paragraph>What days is the offer valid?</Typography.Paragraph>
					{errors.offerValidDays && <Typography.Paragraph type='danger'>You need to add at least one item</Typography.Paragraph>}
				</Col>
				<Col span={6} >
					<Checkbox
						indeterminate={indeterminate}
						onChange={onCheckAllChange}
						checked={checkAll}
					>
						All days
					</Checkbox>
				</Col>
			</Row>
			<Row>
				<Checkbox.Group options={DAYS_OPTIONS} onChange={onChange} value={values.offerValidDays} />
			</Row>
			<br />
			<Row>
				<Col span={18}>
					<Typography.Paragraph>When is the offer valid ?</Typography.Paragraph>
				</Col>
				<Col span={6} >
					<Checkbox checked={isAllDay} onChange={onTimeChange}>
						All day
					</Checkbox>
				</Col>
			</Row>
			{!isAllDay &&
				<Row>
					<Form.Item name={`offerValidHours.from`}>
						<FormikField name={`offerValidHours.from`}>
							{({ field }) => (
								<TimePicker
									{...field}
									allowClear={false}
									name={`offerValidHours.from`}
									value={values.offerValidHours ? moment(values.offerValidHours.from, TIME_FORMAT) : undefined}
									onChange={value => setFieldValue(`offerValidHours.from`, moment(value).format('HHmm'))}
									onBlur={() => setFieldTouched(`offerValidHours.from`, true)}
									format={TIME_FORMAT}
								/>
							)}
						</FormikField>
					</Form.Item>
					<div className="ant-input-group-addon" style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>to</div>
					<Form.Item name={`offerValidHours.to`}>
						<FormikField name={`offerValidHours.to`}>
							{({ field }) => (
								<TimePicker
									{...field}
									allowClear={false}
									name={`offerValidHours.to`}
									value={values.offerValidHours ? moment(values.offerValidHours.to, TIME_FORMAT) : undefined}
									onChange={value => setFieldValue(`offerValidHours.to`, moment(value).format('HHmm'))}
									onBlur={() => setFieldTouched(`offerValidHours.to`, true)}
									format={TIME_FORMAT}
								/>
							)}
						</FormikField>
					</Form.Item>
				</Row>
			}
		</>
	)
}

function OfferInformation({ values, setFieldValue, }) {
	function handleUpload(data) {
		setFieldValue('imagePublicId', data.public_id);
		setFieldValue('imageUrl', data.secure_url);
	}

	return (
		<>
			<ImageDropzone imageUrl={values.imageUrl} onUpload={handleUpload} />
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Form.Item label="Name [IS]" name='name.is'>
						<Input
							name='name.is'
							placeholder="Name [IS]"
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Name [EN]" name='name.en'>
						<Input
							name='name.en'
							placeholder="Name [EN]"
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Form.Item label="Description [IS]" name='description.is'>
						<Input
							name='description.is'
							placeholder="Name [IS]"
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Description [EN]" name='description.en'>
						<Input
							name='description.en'
							placeholder="Description [EN]"
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Form.Item label="Category [slug]" name='category'>
						<Input
							name='category'
							placeholder="kebab"
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Form.Item label={`Price`} name={`price`}>
						<Input
							type='number'
							name={`price`}
							placeholder={`Price`}
							suffix={'ISK'}
						/>
					</Form.Item>
				</Col>
			</Row>
		</>
	)
}