import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons'
import { SINGLE_OFFER } from '../../../graphql/queries'
import { ADMIN_UPDATE_OFFER, ADMIN_DELETE_OFFER } from '../../../graphql/mutations'
import { displayGraphqlErrors } from '../../../graphql/utils'
import { useHistory } from 'react-router-dom'
import {
	Result, message
} from 'antd'
import { OfferForm } from './components'
import _ from 'lodash';

export default function EditOffer() {
	const { id } = useParams();
	const history = useHistory();
	const { data, loading, refetch } = useQuery(SINGLE_OFFER, { variables: { id } });
	const [editOffer] = useMutation(ADMIN_UPDATE_OFFER)
	const [deleteOffer] = useMutation(ADMIN_DELETE_OFFER);

	async function handleEdit(input) {
		try {
			await editOffer({ variables: { id, input: _.omit(input, ['undefined', 'imageUrl']) }})
			message.info('Offer updated successfully')
			refetch();
		} catch (err) {
			displayGraphqlErrors(err);
		}
	}

	async function handleDelete () {
		try {
			const { data } = await deleteOffer({ variables: { id }});
			message.info(data.adminDeleteOffer.message);
			history.replace('/offers');
		} catch (err) {
			displayGraphqlErrors(err);
		}
	}

	if (!data || loading) {
		return (
			<Result
				icon={<LoadingOutlined spin/>}
			/>
		)
	}
	return (
		<OfferForm
			offer={data.adminSingleOffer}
			onSubmit={handleEdit}
			handleDelete={handleDelete}
		/>
	)
}
