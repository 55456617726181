import React from 'react'
import { useMutation } from '@apollo/react-hooks';
import { OfferForm } from './components'
import { displayGraphqlErrors } from '../../../graphql/utils'
import { ADMIN_CREATE_OFFER } from '../../../graphql/mutations'
import { useHistory } from 'react-router-dom'
import _ from 'lodash';

export default function CreateOffer() {
	const [adminCreateOffer] = useMutation(ADMIN_CREATE_OFFER);
	const history = useHistory();

	async function handleCreate(input) {
		try {
			const { data } = await adminCreateOffer({ variables: { input: _.omit(input, ['undefined', 'imageUrl']) } })
			history.replace(`/offers/${data.adminCreateOffer.id}/edit`)
		} catch (err) {
			displayGraphqlErrors(err);
		}
	}

	return (
		<OfferForm
			offer={null}
			onSubmit={handleCreate}
		/>
	)
}
