import React from 'react'
import { Result, Tabs } from 'antd'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_BRANCHES } from '../../../graphql/queries'
import { LoadingOutlined } from '@ant-design/icons'
import OrderDetail from './components/orderDetail'

const { TabPane } = Tabs
export default function Order() {
	const { data } = useQuery(INDEX_BRANCHES, { variables: { first: 100, after: 0 }, fetchPolicy: 'network-only' });

	if (data && data.adminIndexBranches) {
		return (
			<>
				<Tabs
          defaultActiveKey={'-1'}
        >
            <TabPane tab={'No Location'} key={'-1'}>
              <p>Choose location</p>
			  {/* <OrderDetail branch={null}/> */}
            </TabPane>
					{data.adminIndexBranches.edges.map(({ node }, index) => {
						return (
							<TabPane tab={node.name} key={index.toString()}>
								<OrderDetail branch={node}/>
							</TabPane>
						)
					})}
				</Tabs>
			</>
		)
	} else {
		return (
			<Result
				icon={<LoadingOutlined spin/>}
			/>
		)
	}
}
