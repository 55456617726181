import React, { Component, useState, useEffect, useRef } from "react";

// UI
import {
  Table,
  Tag,
  Typography,
  Input,
  message,
  Button,
  Modal,
  Switch,
  Popover,
  Divider,
} from "antd";
import { PrinterFilled } from "@ant-design/icons";

// Graphql
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ADMIN_INDEX_ORDERS } from "../../../../graphql/queries";
import {
  ADMIN_MARK_AS_PRINTED,
  ADMIN_VOID_ORDER,
  ADMIN_DELETE_ORDER,
  ADMIN_UPDATE_ORDER_STATUS,
} from "../../../../graphql/mutations";
import { displayGraphqlErrors } from "../../../../graphql/utils";

// Util
import EstimatedTimesInput from "./timesInput";
import moment from "moment";
import _ from "lodash";
import { isAdmin } from "../../../../services/token";
import { useReactToPrint } from "react-to-print";

const formatISK = (price) =>
  price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : null;

const PAGE_SIZE = 25;
const { Column, ColumnGroup } = Table;
const { Search } = Input;

const statuses = {
  due: {
    color: "volcano",
    label: "óstaðfest",
  },
  paid: {
    color: "lime",
    label: "greitt",
  },
  "in progress": {
    color: "cyan",
    label: "i vinnslu",
  },
  ready: {
    color: "green",
    label: "tilbúin",
  },
  delivered: {
    color: "#d8d8d8",
    label: "afhent",
  },
  cancelled: {
    color: "#d8d8d8",
    label: "hætt við",
  },
  valitorPending: {
    color: "#d8d8d8",
    label: "Pending Payment",
  },
};

export default function OrderDetail({ branch }) {
  const [after, setAfter] = useState(0);
  const [voidModalVisible, setVoidModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [automaticPrintingModalVisible, setAutomaticPrintingModalVisible] =
    useState(false);
  const [currentVoidOrderId, setCurrentVoidOrderID] = useState(null);
  const [currentDeleteOrderId, setCurrentDeleteOrderId] = useState(null);
  const [phone, setPhone] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [automaticPrintingEnabled, setAutomaticPrintingEnabled] =
    useState(false);
  const [singlePrint, setSinglePrint] = useState(null);

  const { data, loading, refetch, networkStatus } = useQuery(
    ADMIN_INDEX_ORDERS,
    {
      variables: {
        first: PAGE_SIZE,
        after,
        branch: branch?.id,
        phone,
        orderNumber,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      pollInterval: 20000, //poll on 5 secs.
    }
  );
  const [adminMarkAsPrinted] = useMutation(ADMIN_MARK_AS_PRINTED);
  const [adminVoidOrder] = useMutation(ADMIN_VOID_ORDER);
  const [adminDeleteOrder] = useMutation(ADMIN_DELETE_ORDER);
  const [adminUpdateOrderStatus] = useMutation(ADMIN_UPDATE_ORDER_STATUS);

  // Printing handlers
  const printComponentRef = useRef();
  const singlePrintComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });
  const handleSinglePrint = useReactToPrint({
    content: () => singlePrintComponentRef.current,
  });

  function turnOnAutomaticPrinting() {
    setAutomaticPrintingEnabled(true);
    setAutomaticPrintingModalVisible(false);
  }

  function closeAutomaticPrintingModal() {
    setAutomaticPrintingModalVisible(false);
  }

  function handleAutomaticPrintingToggle(newValue) {
    if (!newValue) {
      setAutomaticPrintingEnabled(false);
    } else {
      setAutomaticPrintingModalVisible(true);
    }
  }

  function handleTableChange(pagination, filters, sorter) {
    const currentPage = pagination.current - 1;
    setAfter(currentPage * PAGE_SIZE);
    refetch();
  }

  async function handleOrderStatusChange(order, status) {
    try {
      let orders = data.adminIndexOrders.edges.map((o) => o.node);
      let orderObject = orders.find((o) => o.id === order);

      if (orderObject.status === "cancelled") {
        message.info("Ekki hægt að skráð afbókaðar pantanir greiddar");
        return;
      }

      const { data: updateData } = await adminUpdateOrderStatus({
        variables: { order, status },
      });
      message.info(updateData.adminUpdateOrderStatus.message);
      refetch();
    } catch (err) {
      console.log(err);
      displayGraphqlErrors(err);
    }
  }

  async function voidOrder() {
    try {
      const { data } = await adminVoidOrder({
        variables: { order: currentVoidOrderId },
      });
      message.info(data.adminVoidOrder.message);
      refetch();
    } catch (err) {
      displayGraphqlErrors(err);
    } finally {
      closeVoidOrderModal();
    }
  }

  function openVoidOrderModal(order) {
    setVoidModalVisible(true);
    setCurrentVoidOrderID(order);
  }

  function closeVoidOrderModal() {
    setVoidModalVisible(false);
    setCurrentVoidOrderID(null);
  }

  function openDeleteOrderModal(order) {
    setDeleteModalVisible(true);
    setCurrentDeleteOrderId(order);
  }

  function closeDeleteOrderModal() {
    setDeleteModalVisible(false);
    setCurrentDeleteOrderId(null);
  }

  async function deleteOrder() {
    try {
      const { data } = await adminDeleteOrder({
        variables: { order: currentDeleteOrderId },
      });
      message.info(data.adminDeleteOrder.message);
      refetch();
    } catch (err) {
      displayGraphqlErrors(err);
    } finally {
      closeDeleteOrderModal();
    }
  }

  useEffect(() => {
    const markAsPrinted = async (edges) => {
      try {
        // Bara greiddar eða pickup ógreiddar
        let orders = edges
          .filter(({ node }) => {
            if (!node.isPrinted) {
              if (node.status === "paid") {
                return true;
              } else if (node.paymentMethod === "Pop") {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          })
          .map(({ node }) => node.id);

        if (orders.length && automaticPrintingEnabled) {
          //Print the orders
          handlePrint();

          // Mark the orders
          const { data } = await adminMarkAsPrinted({ variables: { orders } });
          refetch();
          message.info(data.adminMarkAsPrinted.message);
        }
      } catch (err) {
        displayGraphqlErrors(err);
      }
    };
    if (networkStatus === 7 && data && data.adminIndexOrders) {
      markAsPrinted(data.adminIndexOrders.edges);
    }
  }, [
    networkStatus,
    data,
    loading,
    refetch,
    adminMarkAsPrinted,
    automaticPrintingEnabled,
    handlePrint,
  ]);

  useEffect(() => {
    refetch();
  }, [phone, refetch]);

  useEffect(() => {
    refetch();
  }, [orderNumber, refetch]);

  const manualPrint = async (order) => {
    await setSinglePrint(order);
    handleSinglePrint();
    await adminMarkAsPrinted({ variables: { orders: [order.id] } });
    refetch();
  };

  const getToppingsRender = (toppings) => {
    if (!toppings) return null;
    return toppings.map(({ topping: { en }, amount }) => (
      <Tag>
        {en}
        {amount > 1 ? `x ${amount}` : ""}
      </Tag>
    ));
  };

  const renderToppings = (
    toppingsSelectionSide1,
    toppingsSelectionSide2,
    isDivided
  ) => {
    let FirstToppings = getToppingsRender(toppingsSelectionSide1);
    let SecondToppings = getToppingsRender(toppingsSelectionSide2);
    return (
      <>
        <Typography.Text>
          <strong>{SecondToppings && isDivided ? "First half:" : ""}</strong>{" "}
          {FirstToppings}
        </Typography.Text>
        {SecondToppings && isDivided && (
          <>
            <br />
            <Typography.Text>
              <strong>Second half: </strong> {SecondToppings}
            </Typography.Text>
          </>
        )}
      </>
    );
  };

  const renderMenuItemTable = (menuItems, isMenuItemOffer) => {
    if (!menuItems) return null;
    return (
      <>
        <Divider orientation="left" plain={isMenuItemOffer}>
          {"Menu Items"}
        </Divider>
        <Table
          bordered
          dataSource={menuItems}
          rowKey={(record) => record.name.en}
          pagination={false}
        >
          <Column
            title="Name"
            key="name"
            render={(text, row) => {
              return <span>{row.name.en}</span>;
            }}
          />
        </Table>
      </>
    );
  };

  const renderPizzaItemTable = (pizzas, isPizzaOffer) => {
    if (!pizzas) return null;
    return (
      <>
        <Divider orientation="left" plain={isPizzaOffer}>
          {"Pizzas"}
        </Divider>
        <Table
          bordered
          dataSource={pizzas}
          rowKey={(record) => record.name.en}
          pagination={false}
        >
          <Column
            title="Size"
            key="size"
            render={(text, row) => {
              return (
                <span>
                  <strong>{row.size.toUpperCase()}</strong>
                </span>
              );
            }}
          />
          <Column
            title="Name"
            key="name"
            render={(text, row) => {
              return (
                <>
                  <span>
                    <strong>{row.pizzaName2?.en ? "First half: " : ""}</strong>
                    {row.name ? row.name.en : "Custom pizza"}
                  </span>
                  <br />
                  {row.pizzaName2?.en && (
                    <span>
                      <strong>Second half: </strong>
                      {row.pizzaName2 ? row.pizzaName2.en : ""}
                    </span>
                  )}
                </>
              );
            }}
          />
          <Column
            title="Toppings"
            key="toppings"
            render={(text, row) => {
              if (isPizzaOffer) {
                return renderToppings(
                  row.toppings,
                  row.toppings2,
                  row.isDivided
                );
              } else {
                return renderToppings(
                  row.toppingsSelectionSide1,
                  row.toppingsSelectionSide2,
                  row.isDivided
                );
              }
            }}
          />
        </Table>
      </>
    );
  };

  const renderOfferItemTable = (offers) => {
    if (!offers) return null;
    return offers.map((offer) => {
      const { name, details } = offer;
      var grouped = _.mapValues(_.groupBy(details, "type"), (clist) =>
        clist.map((cartItem) => _.omit(cartItem, "type"))
      );
      return (
        <>
          <Divider orientation="left">Offer: {name.en}</Divider>
          {renderPizzaItemTable(grouped["Pizza"], true)}
          {renderMenuItemTable(grouped["MenuItem"], true)}
        </>
      );
    });
  };

  const renderDeliveryInfo = (deliveryMethod, deliveryAddress) => {
    if (deliveryMethod === "delivery") {
      const { postalCode, city, address, message } = deliveryAddress;
      return (
        <>
          <Divider orientation="left">Delivery Information</Divider>
          <Typography.Text>{`${address}, ${postalCode} ${city}`}</Typography.Text>
          <br />
          {!!message && <Typography.Text>Message: {message}</Typography.Text>}
        </>
      );
    }
    return null;
  };

  const expandedRowRender = (record) => {
    const { cart, voided, id, deliveryMethod, deliveryAddress } = record.node;
    var grouped = _.mapValues(_.groupBy(cart, "type"), (clist) =>
      clist.map((cartItem) => _.omit(cartItem, "type"))
    );
    const isUserAdmin = isAdmin();
    return (
      <>
        {renderDeliveryInfo(deliveryMethod, deliveryAddress)}
        <Divider orientation="left">Cart</Divider>
        {renderPizzaItemTable(grouped["Pizza"], false)}
        {renderMenuItemTable(grouped["MenuItem"])}
        {renderOfferItemTable(grouped["Offer"])}
        {isUserAdmin && (
          <>
            {voided ? (
              <Button onClick={() => openDeleteOrderModal(id)} type="primary">
                DELETE ORDER
              </Button>
            ) : (
              <Button onClick={() => openVoidOrderModal(id)} type="primary">
                Void Order
              </Button>
            )}
          </>
        )}
      </>
    );
  };

  let ordersToBePrinted = data?.adminIndexOrders.edges.filter((item) => {
    return !item.node.isPrinted &&
      item.node.status !== "cancelled" &&
      item.node.status !== "valitorPending"
      ? item
      : false;
  });

  return (
    <>
      <EstimatedTimesInput branch={branch} />
      <div
        className="toolbar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Search
          placeholder="Input phone number"
          onSearch={(value) => setPhone(value)}
          style={{ width: 200 }}
        />
        <Search
          placeholder="Input order number"
          onSearch={(value) => setOrderNumber(value)}
          style={{ width: 200 }}
        />

        <label>
          <p style={{ display: "inline-block", marginRight: "20px" }}>
            Automatic printing
          </p>
          <Switch
            checked={automaticPrintingEnabled}
            onChange={(checked) => handleAutomaticPrintingToggle(checked)}
          />
        </label>
      </div>
      <Modal
        title="Turn on automatic printing"
        visible={automaticPrintingModalVisible}
        onOk={turnOnAutomaticPrinting}
        onCancel={closeAutomaticPrintingModal}
      >
        <p>
          Are you sure you want to turn on automatic printing. Note: this will
          print all new orders to the printer connected to this computer.
        </p>
      </Modal>
      <Modal
        title="Void Order"
        visible={voidModalVisible}
        onOk={voidOrder}
        onCancel={closeVoidOrderModal}
      >
        <p>
          Are you sure you want to void this order this action cannot be
          reversed
        </p>
      </Modal>
      <Modal
        title="Delete Order"
        visible={deleteModalVisible}
        onOk={deleteOrder}
        onCancel={closeDeleteOrderModal}
      >
        <p>
          Are you sure you want to delete this order this action cannot be
          reversed
        </p>
      </Modal>
      <Table
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record),
        }}
        bordered
        rowKey={(record) => record.node.id}
        dataSource={data ? data.adminIndexOrders.edges : []}
        pagination={{
          showSizeChanger: false,
          pageSize: PAGE_SIZE,
          defaultCurrent: 1,
          total: data && data.adminIndexOrders.totalCount,
        }}
        // loading={loading}
        onChange={handleTableChange}
      >
        <Column
          title="Order number"
          key="orderNumber"
          render={(text, row) => {
            return <span>{row.node?.orderNumber}</span>;
          }}
        />
        <Column
          title="Date"
          key="createdAt"
          render={(text, row) => {
            return (
              <span>
                {moment
                  .unix(row.node.createdAt / 1000)
                  .format("MM/DD/YYYY HH:mm:ss")}
              </span>
            );
          }}
        />
        <ColumnGroup title="Customer" key="customer">
          <Column
            title="Name"
            key="name"
            render={(text, row) => {
              return <span>{row.node.user.name}</span>;
            }}
          />
          <Column
            title="Phone"
            key="phone"
            render={(text, row) => {
              return <span>{row.node.user.phone}</span>;
            }}
          />
        </ColumnGroup>
        <Column
          title="Message"
          key="message"
          render={(text, row) => {
            return <span>{row.node.message}</span>;
          }}
        />
        <Column
          title="Delivery Method"
          key="deliveryMethod"
          render={(text, row) => {
            return <Tag>{row.node.deliveryMethod.toUpperCase()}</Tag>;
          }}
        />
        <ColumnGroup title="Payment" key="payment">
          <Column
            title="Total price"
            key="price"
            render={(text, row) => {
              return <Tag>{row.node.totalPrice} ISK</Tag>;
            }}
          />
          <Column
            title="Payment method"
            key="paymentMethod"
            render={(text, row) => {
              return <Tag>{row.node.paymentMethod}</Tag>;
            }}
          />
          <Column
            title="Payment status"
            key="status"
            render={(text, row) => {
              return <Tag>{row.node.status}</Tag>;
            }}
          />
        </ColumnGroup>
        <Column
          width={20}
          title=""
          key="printed"
          render={(text, row) => {
            return (
              <Button
                onClick={(e) => manualPrint(row.node)}
                style={{
                  backgroundColor:
                    row.node.status === "cancelled" ||
                    row.node.status === "valitorPending" ||
                    row.node.status === "aurPending" ||
                    row.node.status === "netgiroPending"
                      ? "#ddd"
                      : row.node.isPrinted
                      ? "#bfffbf"
                      : "#ffa3a3",
                }}
              >
                <PrinterFilled />
              </Button>
            );
          }}
        />
        <Column
          width={20}
          title=""
          key="tools"
          render={(text, row) => (
            <Popover
              content={Object.keys(statuses).map((status) => (
                <Button
                  onClick={(e) => handleOrderStatusChange(row.node.id, status)}
                >
                  {statuses[status].label}
                </Button>
              ))}
              title="Aðgerðir"
              trigger="click"
            >
              <Button
                style={{ marginRight: "10px" }}
                type="dashed"
                size="small"
              >
                Uppfæra
              </Button>
            </Popover>
          )}
        />
      </Table>
      <div style={{ height: "10px", overflow: "hidden", opacity: 0 }}>
        {data && ordersToBePrinted?.length && (
          <ComponentToPrint
            orders={ordersToBePrinted}
            ref={printComponentRef}
          />
        )}
      </div>
      <div style={{ height: "10px", overflow: "hidden", opacity: 0 }}>
        {data && (
          <ComponentToPrintSingle
            order={singlePrint}
            ref={singlePrintComponentRef}
          />
        )}
      </div>
    </>
  );
}

class ComponentToPrint extends Component {
  render() {
    const { orders } = this.props;

    if (!orders || !orders.length);

    return (
      <div style={{ marginTop: "20px", marginLeft: "20px" }}>
        {orders.map(({ node }, i) => orderLayout(node))}
      </div>
    );
  }
}

const toppingsDelta = (originalToppings, selectedToppings) => {
  // Check added / removed toppings
  // Check different number toppings
  if (originalToppings && selectedToppings) {
    originalToppings = originalToppings?.map((topping) => ({
      amount: topping.amount,
      id: topping.topping._id,
      name: topping.topping.name,
    }));

    let extraTopping = selectedToppings.filter(
      (topping) =>
        !originalToppings.map((t) => t.id).includes(topping.toppingId)
    );
    let missingToppings = originalToppings.filter(
      (topping) =>
        !selectedToppings.map((t) => t.toppingId).includes(topping.id)
    );
    let diffAmount = selectedToppings.filter((topping) => {
      let matchtable = originalToppings.find(
        (ot) => ot.id === topping.toppingId && ot.amount !== topping.amount
      );
      return matchtable;
    });

    // console.log('extr', extraTopping);
    // console.log('missing', missingToppings);
    // console.log('diff', diffAmount);

    return (
      <>
        {!!missingToppings.length && (
          <>
            Mínus: {missingToppings.map((t) => `${t.amount} x ${t.name?.is} `)}
            <br />
          </>
        )}
        {!!extraTopping.length && (
          <>
            Auka: {extraTopping.map((t) => `${t.amount} x ${t.topping?.is} `)}
            <br />
          </>
        )}
        {!!diffAmount.length && (
          <>
            Breytt magn:{" "}
            {diffAmount.map((t) => `${t.amount} x ${t.topping?.is} `)}
            <br />
          </>
        )}
      </>
    );
  }
};

class ComponentToPrintSingle extends Component {
  render() {
    const { order } = this.props;

    return order && orderLayout(order);
  }
}

const orderLayout = (order) => (
  <div style={{ marginTop: "20px", marginLeft: "20px", height: "100vh" }}>
    <div className="header">
      <h2 style={{ width: "95%", textAlign: "center" }}>Castello</h2>
      <h4 style={{ width: "95%", textAlign: "center" }}>
        {order?.orderNumber}
      </h4>
      <div
        style={{
          width: "95%",
          borderBottom: "2px solid black",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      ></div>
      <em>
        {moment.unix(order.createdAt / 1000).format("MM/DD/YYYY HH:mm:ss")}
      </em>
      <div
        style={{
          width: "95%",
          borderBottom: "2px solid black",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      ></div>
    </div>
    <div className="order">
      <h3>Samtals: {formatISK(order.totalPrice)} kr.</h3>
      <h3>
        {order.deliveryMethod === "pickup" ? "Sækja" : "Heimsent"} -{" "}
        {order.branch.name}
      </h3>
      <table
        style={{ width: "95%", fontSize: "12px", borderSpacing: "0 15px" }}
      >
        {order.status === "cancelled" ? (
          <tr>
            <td>Staða: </td>
            <td>Hætt við ( CANCELLED )</td>
          </tr>
        ) : order.status === "due" && order.paymentMethod === "Card" ? (
          <tr>
            <td>Staða: </td>
            <td>ÓLJÓS - SKOÐAÐU KERFI</td>
          </tr>
        ) : (
          <>
            <tr>
              <td>Staða: </td>
              <td>{order.status === "paid" ? "Greitt" : "Ógreitt"}</td>
            </tr>
            <tr>
              <td>Greiðsluleið: </td>
              <td>
                {order.paymentMethod === "Card"
                  ? "KORT"
                  : order.paymentMethod === "Pop"
                  ? "GREIÐA Á STAÐNUM"
                  : order.paymentMethod?.toUpperCase()}
              </td>
            </tr>
          </>
        )}
      </table>
      {order.message && (
        <>
          <b>Athugasemd: </b>
          <p>{order.message}</p>
        </>
      )}
    </div>
    <div className="customer">
      <div
        style={{
          width: "95%",
          borderBottom: "2px solid black",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      ></div>
      <h3>Upplýsingar um viðskiptavin</h3>
      <div
        style={{
          width: "95%",
          borderBottom: "2px solid black",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      ></div>

      <table
        style={{ width: "95%", fontSize: "12px", borderSpacing: "0 15px" }}
      >
        <tr>
          <td>Nafn: </td>
          <td>{order.user?.name}</td>
        </tr>
        <tr>
          <td>Símanúmer: </td>
          <td>{order.user?.phone}</td>
        </tr>

        {order.deliveryAddress?.address && (
          <tr>
            <td>Heimilisfang: </td>
            <td>{order.deliveryAddress.address}</td>
          </tr>
        )}
        {order.deliveryAddress?.city && (
          <tr>
            <td>Borg: </td>
            <td>{order.deliveryAddress.city}</td>
          </tr>
        )}
        {order.deliveryAddress?.postalCode && (
          <tr>
            <td>Póstnúmer: </td>
            <td>{order.deliveryAddress.postalCode}</td>
          </tr>
        )}
        {order.deliveryAddress?.message && (
          <tr>
            <td>Skilaboð: </td>
            <td>{order.deliveryAddress.message}</td>
          </tr>
        )}
      </table>
      <div
        style={{
          width: "95%",
          borderBottom: "2px solid black",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      ></div>
    </div>

    <div>
      <table
        style={{ width: "95%", fontSize: "12px", borderSpacing: "0 15px" }}
      >
        <thead>
          <td>Item.</td>
          <td>Price.</td>
        </thead>
        {order.cart.map((item, j) => {
          if (item.type === "Offer") {
            return (
              <>
                <tr key={j}>
                  <td>
                    <b>{item.name?.is}</b>
                  </td>
                  <td>{formatISK(item.price)}</td>
                </tr>

                {item.details.map((itemDetail, i) => (
                  <tr>
                    <td>
                      <b>
                        {itemDetail.isDivided && "Half / Half"}{" "}
                        {itemDetail.size}{" "}
                        {itemDetail.type === "Pizza" ? "Pizza" : ""}{" "}
                        {itemDetail?.name?.is}
                      </b>
                      <br />
                      <p>
                        {itemDetail.isDivided && "Helmingur 1: "}
                        {itemDetail.toppings ? " - " : ""}{" "}
                        {itemDetail.toppings?.map(
                          (t) => `${t.amount} x ${t.topping.is}  `
                        )}
                      </p>{" "}
                      <br />
                      {itemDetail.isDivided && (
                        <p>
                          Helmingur 2: {itemDetail.toppings2 ? " - " : ""}{" "}
                          {itemDetail.toppings2?.map(
                            (t) => `${t.amount} x ${t.topping.is}  `
                          )}
                        </p>
                      )}
                    </td>
                  </tr>
                ))}

                <tr>
                  <td>-------------------------</td>
                </tr>
              </>
            );
          } else if (item.type === "Pizza") {
            return (
              <>
                {!!item.isDivided && (
                  <td>
                    <b>Half / Half</b> size: {item.size}
                  </td>
                )}
                <tr key={j}>
                  {item.isDivided ? (
                    <td>
                      <br />
                      <b>Helmingur 1:</b> {item.name?.is}
                    </td>
                  ) : (
                    <td>
                      <b>{item.name?.is}</b> size: {item.size}
                    </td>
                  )}
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    {toppingsDelta(
                      JSON.parse(item.payload1)?.toppings,
                      item.toppingsSelectionSide1
                    )}
                    {/* <p>{item.toppingsSelectionSide1?.map(t => `${t.amount} x ${t.topping.is}  `)}</p> */}
                  </td>
                  <td>{formatISK(item.price)}</td>
                </tr>

                {item.isDivided && (
                  <>
                    <tr>
                      <td>
                        <b>Helmingur 2:</b> {item.pizzaName2?.is}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {/* <p>{item.toppingsSelectionSide2?.map(t => `${t.amount} x ${t.topping.is}  `)}</p> */}
                        {toppingsDelta(
                          JSON.parse(item.payload2)?.toppings,
                          item.toppingsSelectionSide2
                        )}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>-------------------------</td>
                </tr>
              </>
            );
          } else {
            return (
              <>
                <tr key={j}>
                  <td>
                    <b>{item.name?.is}</b>
                  </td>
                  <td>{formatISK(item.price)}</td>
                </tr>
                <tr>
                  <td>-------------------------</td>
                </tr>
              </>
            );
          }
        })}
      </table>
      {/* 
                    <ol style={{ paddingLeft: '20px' }}>
                        {order.cart.map((item, j) =>
                            <li key={j}>
                                <b>{item.type}</b>
                                <em> {item.name?.is}</em>
                                {item?.toppingsSelectionSide1 && <p>Side 1: {item?.toppingsSelectionSide1?.length && item.toppingsSelectionSide1.map(topping => `${topping.amount} x ${topping.topping.en}`).join(',    ')}</p>}
                                {item.isDivided && <p>Side 2: {item?.toppingsSelectionSide2?.length && item.toppingsSelectionSide2.map(topping => `${topping.amount} x ${topping.topping.en}`).join(',    ')}</p>}
                            </li>
                        )}
                    </ol> */}

      <div
        style={{
          width: "95%",
          borderBottom: "2px solid black",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      ></div>

      <table
        style={{ width: "95%", fontSize: "12px", borderSpacing: "0 15px" }}
      >
        <tr>
          <td>Þín pöntun: </td>
          <td>{formatISK(order.totalPriceWithoutVat)}</td>
        </tr>
        <tr>
          <td>Þar af vsk (11%): </td>
          <td>{formatISK(order.totalVat)}</td>
        </tr>
        <tr>
          <td>Samtals: </td>
          <td>{formatISK(order.totalPrice)}</td>
        </tr>
      </table>
    </div>
  </div>
);
