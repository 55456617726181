import React from 'react'
import {
	Drawer,
	Button,
	Select,
	Row,
	Col,
	message,
} from 'antd'
import { Formik, Field as FormikField } from 'formik';
import { Form, Input, InputNumber, Radio } from 'formik-antd'
import * as Yup from 'yup'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADMIN_UPDATE_TOPPING, ADMIN_CREATE_TOPPING, ADMIN_DELETE_TOPPING } from '../../../../graphql/mutations'
import { INDEX_TOPPING_CATEGORIES } from '../../../../graphql/queries'
import { displayGraphqlErrors } from '../../../../graphql/utils'

const { Option } = Select;

const ToppingSchema = Yup.object().shape({
	name: Yup.object().shape({
		en: Yup.string().required('[EN] Name is required'),
		is: Yup.string().required('[IS] Name is required'),
	}),
	priceSmall: Yup.number().min(0).required(),
	priceMedium: Yup.number().min(0).required(),
	priceLarge: Yup.number().min(0).required(),
	maxAmount: Yup.number().min(1).max(3),
	category: Yup.string().required('Category is required'),
	active: Yup.boolean()
});

export default function ToppingDetails({ visible, onClose, topping }) {
	const { data: toppingCategoryData } = useQuery(INDEX_TOPPING_CATEGORIES, { variables: { first: 100, after: 0 }, fetchPolicy: 'network-only' })
	const [adminUpdateTopping] = useMutation(ADMIN_UPDATE_TOPPING)
	const [adminCreateTopping] = useMutation(ADMIN_CREATE_TOPPING)
	const [adminDeleteTopping] = useMutation(ADMIN_DELETE_TOPPING)

	async function handleDelete () {
		try {
			const { data } = await adminDeleteTopping({ variables: { id: topping.id } })
			message.info(data.adminDeleteTopping.message)
			onClose(true)
		} catch (err) {
			displayGraphqlErrors(err)
		}
	}

	return (
		<Drawer
			title={topping ? 'Edit Topping' : 'Create Topping'}
			width={720}
			onClose={onClose}
			visible={visible}
			bodyStyle={{ paddingBottom: 80 }}
		>
			<Formik
				enableReinitialize={true}
				initialValues={{
					name: topping ? topping.name : { en: '', is: '' },
					priceSmall: topping ? topping.priceSmall : 0,
					priceMedium: topping ? topping.priceMedium : 0,
					priceLarge: topping ? topping.priceLarge : 0,
					maxAmount: topping ? topping.maxAmount : 1,
					category: topping ? topping.category.id : '',
					active: topping ? topping.active : true
				}}
				validationSchema={ToppingSchema}
				onSubmit={async values => {
					try {
						if (topping && topping.id) {
							await adminUpdateTopping({ variables: { id: topping.id, input: values }} );
						} else {
							await adminCreateTopping({ variables: { input: values } })
						}
						onClose(true)
					} catch (err) {
						displayGraphqlErrors(err)
					}
				}}
			>
				{({ errors, touched, handleSubmit, setFieldTouched, setFieldValue, values }) => {
					return (
						<>
							<Form
								layout='vertical'
								hideRequiredMark
							>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item label="Name [IS]" name='name.is' validate={errors.name && errors.name.is && touched.name && touched.name.is}>
											<Input
												name='name.is'
												value={values.name.is}
												placeholder="Name [IS]"
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Name [EN]" name='name.en' validate={errors.name && errors.name.en && touched.name && touched.name.en}>
											<Input
												name='name.en'
												value={values.name.en}
												placeholder="Name [EN]"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item label="Category" name='category' validate={errors.category && touched.category}>
											<FormikField
												name="category"
												render={({ field }) => {
													if (toppingCategoryData && toppingCategoryData.adminIndexToppingCategories) {
														return (
															<Select
																{...field}
																onChange={value => setFieldValue("category", value)}
																onBlur={() => setFieldTouched("category", true)}
																value={values.category}
															>
																{toppingCategoryData.adminIndexToppingCategories.edges.map(({ node }, index) => (
																	<Option key={node.id} value={node.id}>
																		{node.name.en}
																	</Option>
																))}
															</Select>
														)
													}
													return null;
												}}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Max amount" name='maxAmount' validate={errors.maxAmount && touched.maxAmount}>
											<InputNumber
												name='maxAmount'
												style={{width: '100%'}}
												value={values.maxAmount}
												min={1}
												max={3}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={8}>
										<Form.Item label={`Price: [Small]`} name={'priceSmall'}>
											<Input
												type='number'
												name={'priceSmall'}
												placeholder={`Price: [Small]`}
												suffix={'ISK'}
											/>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label={`Price: [Medium]`} name={'priceMedium'}>
											<Input
												type='number'
												name={'priceMedium'}
												placeholder={`Price: [Medium]`}
												suffix={'ISK'}
											/>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label={`Price: [Large]`} name={'priceLarge'}>
											<Input
												type='number'
												name={'priceLarge'}
												placeholder={`Price: [Large]`}
												suffix={'ISK'}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Form.Item label="Is active" name='active' validate={errors.active && touched.active}>
									<Radio.Group name='active' value={values.active}>
										<Radio.Button value={true}>Active</Radio.Button>
										<Radio.Button value={false}>Not active</Radio.Button>
									</Radio.Group>
								</Form.Item>
							</Form>
							<div
								style={{
									position: 'absolute',
									right: 0,
									bottom: 0,
									width: '100%',
									borderTop: '1px solid #e9e9e9',
									padding: '10px 16px',
									background: '#fff',
									textAlign: 'right',
								}}
								>
								<div style={{
									display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
								}}>
									<div>
										{topping &&
											<Button onClick={handleDelete} type='danger'>
												Delete topping
											</Button>
										}
									</div>
									<div>
										<Button onClick={onClose} style={{ marginRight: 8 }}>
											Cancel
										</Button>
										<Button onClick={handleSubmit} type="primary">
											Save
										</Button>
									</div>
								</div>
							</div>
						</>	
					)
				}}
			</Formik>
		</Drawer>
	)
}
