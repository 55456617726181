import React from 'react'
import {
	Drawer,
	Button,
	Col,
	Descriptions
} from 'antd'
import { Formik } from 'formik'
import { Form, Input, Checkbox } from 'formik-antd'
import * as Yup from 'yup'
import { useMutation } from '@apollo/react-hooks'
import { ADMIN_UPDATE_USER } from '../../../../graphql/mutations'
import { displayGraphqlErrors } from '../../../../graphql/utils'
import { isAdmin, isEmployee } from '../../../../services/token'

const CustomerSchema = Yup.object().shape({
	blockedReason: Yup.string().required('Reason is required'),
	blocked: Yup.boolean().nullable()
});

export default function CustomerDetails({ visible, onClose, user }) {
	const [adminUpdateUser] = useMutation(ADMIN_UPDATE_USER);
	return (
		<Drawer
			title={'Edit Customer'}
			width={720}
			onClose={onClose}
			visible={visible}
			bodyStyle={{ paddingBottom: 80 }}
		>
			<Descriptions title="User Info">
				<Descriptions.Item label="Name">{user?.name}</Descriptions.Item>
				<Descriptions.Item label="Phone">{user?.phone}</Descriptions.Item>
			</Descriptions>
			{(isEmployee() && user?.blocked) && 
				<Descriptions title="Blocked reason">
					<Descriptions.Item label="Blocked Reason">{user?.blockedReason}</Descriptions.Item>
				</Descriptions>
			}
			{isAdmin() &&
				<Formik
					enableReinitialize={true}
					initialValues={{
						blockedReason: user ? user.blockedReason : '',
						blocked: user ? user.blocked : false,
					}}
					validationSchema={CustomerSchema}
					onSubmit={async values => {
						try {
							await adminUpdateUser({ variables: { id: user.id, input: values }} );
							onClose(true)
						} catch (err) {
							displayGraphqlErrors(err)
						}
					}}
				>
					{({ errors, touched, handleSubmit, setFieldTouched, setFieldValue, values }) => {
						return (
							<>
								<Form
									layout='vertical'
									hideRequiredMark
								>
									<Col span={12}>
										<Form.Item label="Is blocked" name='blocked'>
											<Checkbox name="blocked">
												Is Blocked
											</Checkbox>
										</Form.Item>
									</Col>
									{values.blocked &&
										<Col span={12}>
											<Form.Item label="Blocked Reason" name='blockedReason'>
												<Input
													name='blockedReason'
													value={values.blockedReason}
													placeholder="Blocked reason"
												/>
											</Form.Item>
										</Col>
									}
								</Form>
								<div
									style={{
										position: 'absolute',
										right: 0,
										bottom: 0,
										width: '100%',
										borderTop: '1px solid #e9e9e9',
										padding: '10px 16px',
										background: '#fff',
										textAlign: 'right',
									}}
									>
									<div style={{
										display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
									}}>
										<div>
											<Button onClick={onClose} style={{ marginRight: 8 }}>
												Cancel
											</Button>
											<Button onClick={handleSubmit} type="primary">
												Save
											</Button>
										</div>
									</div>
								</div>
							</>	
						)
					}}
				</Formik>
			}
		</Drawer>
	)
}