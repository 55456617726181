
import React from 'react'
import {
	Button,
	Icon,
	Card,
	Row,
	Col
} from 'antd';
import {
	Form,
	Input,
} from 'formik-antd'
import { Formik } from 'formik';
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { ADMIN_LOGIN_MUTATION } from '../../graphql/mutations';
import { setTokenAsync } from '../../services/token';
import { displayGraphqlErrors } from '../../graphql/utils';

const LoginSchema = Yup.object().shape({
  username: Yup.string()
		.required('Email is required'),
	password: Yup.string()
    .required('Password is required'),
});

export default function AdminLogin() {
	const [login] = useMutation(ADMIN_LOGIN_MUTATION);
	let history = useHistory();
	return (
		<Row type="flex" style={{height: '100vh'}} align="middle">
			<Col span={8} offset={8}>
				<Card title={'Admin login'}>
					<Formik
						initialValues={{
							username: '',
							password: ''
						}}
						validationSchema={LoginSchema}
						onSubmit={async values => {
							try {
								const { data } = await login({ variables: { input: values } })
								await setTokenAsync(data.adminLoginEmployee.token);
								history.push('/');
							} catch (err) {
								displayGraphqlErrors(err);
							}
						}}
					>
						{({ errors, touched, handleSubmit }) => {
							return (
								<Form
									layout='vertical'
									hideRequiredMark
								>
									<Form.Item name='username' validate={errors.username && touched.username}>
										<Input
											prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
											name='username'
											id="#username"
											placeholder="Username"
										/>
									</Form.Item>
									<Form.Item name='password' validate={errors.password && touched.password}>
										<Input.Password
											prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
											name='password'
											placeholder="Password"
											onPressEnter={handleSubmit}
										/>
									</Form.Item>
									<Button type="primary" onClick={handleSubmit}>Login</Button>
								</Form>
							)
						}}
					</Formik>
				</Card>
			</Col>
		</Row>
	)
}
