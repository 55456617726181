import React, { useState } from 'react'
import { Result, Button, Tabs } from 'antd'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_MENU_CATEGORIES } from '../../../graphql/queries'
import ItemTable from './tables/ItemTable'
import {
	LoadingOutlined
} from '@ant-design/icons'
import MenuCategoryDetails from './components/categoryDetail';

const { TabPane } = Tabs
export default function Product() {
	const { data, refetch } = useQuery(INDEX_MENU_CATEGORIES, { variables: { first: 100, after: 0 }, fetchPolicy: 'network-only' });
	const [visible, setVisible] = useState(false)
	const [activeKey, setActiveKey] = useState(null)
	const [menuCategory, setMenuCategory] = useState(null)

	function handleCategoryEdit () {
		let menuCategory = data.adminIndexMenuCategories.edges[activeKey].node
		openDrawer(menuCategory)
	}

	function openDrawer (menuCategory) {
		setMenuCategory(menuCategory)
		setVisible(true)
	}
	
	function onClose () {
		setVisible(false)
		refetch()
	}

	if (data && data.adminIndexMenuCategories) {
		return (
			<>
				<MenuCategoryDetails
					visible={visible}
					onClose={onClose}
					menuCategory={menuCategory}
				/>
				<Tabs
					onChange={(activeKey) => { setActiveKey(activeKey === 'ALL' ? null : activeKey) }}
					defaultActiveKey="ALL"
					tabBarExtraContent={<ExtraContent activeKey={activeKey} onEdit={handleCategoryEdit} onNew={() => openDrawer(null)}/>}
				>
					<TabPane tab="All" key="ALL">
						<ItemTable/>
					</TabPane>
					{data.adminIndexMenuCategories.edges.map(({node}, index) => (
						<TabPane tab={node.name.en} key={index}>
							<ItemTable category={node.id}/>
						</TabPane>
					))}
				</Tabs>
			</>
		)
	} else {
		return (
			<Result
				icon={<LoadingOutlined spin/>}
			/>
		)
	}
}

function ExtraContent ({ activeKey, onEdit, onNew }) {
	return (
		<>
			{activeKey && <Button onClick={onEdit}>Edit Menu Category</Button>}
			<Button onClick={onNew}>New Menu Category</Button>
		</>
	)
}