import React from 'react';
import {
  Route,
  Redirect
} from "react-router-dom";
import { isEmployee } from '../../services/token';

function EmployeeRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const isUserEmployee = isEmployee();
        if (isUserEmployee) {
          return children
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      }}
    />
  )
}

export default EmployeeRoute;