import React, { useState, useEffect } from 'react'
import {
	Button,
	Checkbox,
	Row,
	Col,
	Typography,
	InputNumber
} from 'antd'
import { groupToppings } from '../../../../services/topping';

export default function PizzaToppingSelector ({ toppingData, toppings, handleSubmit, onClose }) {
	const [groupedToppings, setGroupedToppings] = useState(null)
	const [selectedToppings, setSelectedToppings] = useState(toppings)

	useEffect(() => {
		if (toppingData && toppingData.adminIndexToppings) {
			let toppings = groupToppings(toppingData.adminIndexToppings.edges);
			setGroupedToppings(toppings)
		}
	}, [toppingData])

	function onChange (e) {
		let { target } = e;
		let { checked, value } = target;
		if (checked) {
			addTopping(value);
		} else {
			removeTopping(value);
		}
	}

	// Adds topping to the selectedToppings array.
	function addTopping (topping) {
		setSelectedToppings(selectedToppings => selectedToppings.concat({topping, amount: 1}));
	}

	// Removes topping from the selectedtoppings array.
	function removeTopping (topping) {
		setSelectedToppings(selectedToppings.filter((item) => (topping !== item.topping )))
	}

	// When amount is changed, increment or decrement.
	function onAmountChange (topping, value) {
		if (value === 0) {
			removeTopping(topping)
		} else {
			setSelectedToppings(selectedToppings.map((item) => {
				if (item.topping === topping) {
					return {
						...item,
						amount: value
					}
				}
				return item;
			}))
		}
	}

	if (groupedToppings) {
		return Object.keys(groupedToppings).map((category, index) => {
			return (
				<>
					<Typography.Title level={4} style={{marginTop: '1rem'}}>{category}</Typography.Title>
					<Row>
						{groupedToppings[category].toppings.map((item, index) => {
							let toppingValue = selectedToppings.find((topping) => topping.topping === item.id)
							let isChecked = !!toppingValue
							return (
								<Col key={item.id} span={8}>
									<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', overflow: 'hidden', alignItems: 'center', margin: '0.5rem 0.5rem'}}>
										<Checkbox
											checked={isChecked}
											defaultChecked={isChecked}
											value={item.id}
											onChange={onChange}
										>
											{item.name.en}
										</Checkbox>
										{isChecked &&
											<InputNumber
												style={{width: '25%'}}
												value={toppingValue.amount}
												min={0}
												max={item.maxAmount}
												onChange={(value) => onAmountChange(item.id, value)}
											/>
										}
									</div>
								</Col>
							)
						})}
					</Row>
					<div
						style={{
							position: 'absolute',
							right: 0,
							bottom: 0,
							width: '100%',
							borderTop: '1px solid #e9e9e9',
							padding: '10px 16px',
							background: '#fff',
							textAlign: 'right',
						}}
						>
						<Button onClick={onClose} style={{ marginRight: 8 }}>
							Cancel
						</Button>
						<Button onClick={() => handleSubmit(selectedToppings)} type="primary">
							Confirm
						</Button>
					</div>
				</>
			)
		})
	}
	return null
}
