import gql from 'graphql-tag'

export const INDEX_BRANCHES = gql`
	query adminIndexBranches {
		adminIndexBranches {
			totalCount
			edges {
				node {
					id
					name
					phone
					address {
						street
						postalCode
						city
					}
					homeDeliveryPostalCodes
					openingHours {
						mon {
							from
							to
							isClosed
						}
						tue {
							from
							to
							isClosed
						}
						wed {
							from
							to
							isClosed
						}
						thu {
							from
							to
							isClosed
						}
						fri {
							from
							to
							isClosed
						}
						sat {
							from
							to
							isClosed
						}
						sun {
							from
							to
							isClosed
						}
					}
					minutesBeforeOnlineSalesClose
					estimatedPickupTime
					estimatedDeliveryTime
				}
			}
		}
	}
`

export const SINGLE_BRANCH = gql`
	query adminSingleBranch($id: ID!) {
		adminSingleBranch(id: $id) {
			name
			phone
			address {
				street
				postalCode
				city
			}
			homeDeliveryPostalCodes
			openingHours {
				mon {
					from
					to
				}
				tue {
					from
					to
				}
				wed {
					from
					to
				}
				thu {
					from
					to
				}
				fri {
					from
					to
				}
				sat {
					from
					to
				}
				sun {
					from
					to
				}
			}
			minutesBeforeOnlineSalesClose
			estimatedPickupTime
			estimatedDeliveryTime
		}
	}
`

export const INDEX_EMPLOYEES = gql`
	query adminIndexEmployees($first: Int! $after: Int!) {
		adminIndexEmployees(first: $first, after: $after) {
			pageInfo {
				hasNextPage
			}
			totalCount
			edges {
				node {
					name
					role
					username
					id
				}
			}
		}
	}
`

export const INDEX_USERS = gql`
	query adminIndexUsers($first: Int! $after: Int!, $phone: String) {
		adminIndexUsers(first: $first, after: $after, phone: $phone) {
			pageInfo {
				hasNextPage
			}
			totalCount
			edges {
				node {
					id
					phone
					name
					blocked
					blockedReason
					addresses {
						address
						postalCode
						city
					}
				}
			}
		}
	}
`

export const INDEX_DISCOUNTS = gql`
	query adminIndexDiscounts($first: Int!, $after: Int!) {
		adminIndexDiscounts(first: $first, after: $after) {
			pageInfo {
				hasNextPage
			}
			totalCount
			edges {
				node {
					id
					name {
						en
						is
					}
					description {
						en
						is
					}
					isPickup
					isDelivery
					isAllItems
					discountPercentage
					discountValidDays
					active
					allowedItems
					discountType
					createdBy {
						name
					}
				}
			}
		}
	}
`


export const SINGLE_DISCOUNT = gql`
	query singleDiscount($id: ID!) {
		singleDiscount(first: $first, after: $after) {
			id
			slug
			name {
				en
				is
			}
			description {
				en
				is
			}
			isPickup
			isDelivery
			discountPercentage
			discountValidDays
			active
			allowedItems
			discountType
			createdBy
		}
	}
`


export const INDEX_OFFERS = gql`
	query adminIndexOffers($first: Int! $after: Int!) {
		adminIndexOffers(first: $first, after: $after) {
			pageInfo {
				hasNextPage
			}
			totalCount
			edges {
				node {
					id
					slug
					name {
						en
						is
					}
					description {
						en
						is
					}
					category
					imageUrl
        	imagePublicId
					isPickup
					isDelivery
					price
					offerValidDays
					offerValidHours {
						from
						to
					}
				}
			}
		}
	}
`

export const SINGLE_OFFER = gql`
	query adminSingleOffer($id: ID!) {
		adminSingleOffer(id: $id) {
			id
			name {
				en
				is
			}
			description {
				en
				is
			}
			slug
			category
			isPickup
			isDelivery
			price
			isValid
			offerValidHours {
				from
				to
			}
			offerValidDays
			active
			details {
				id
				offerType
				size
				toppingAmount
				allowedItems
				isMenuPizza
			}
			imageUrl
			imagePublicId
		}
	}
`


export const INDEX_PIZZAS = gql`
	query adminIndexPizzas($first: Int!, $after: Int!) {
		adminIndexPizzas(first: $first, after: $after) {
			pageInfo {
				hasNextPage
			}
			totalCount
			edges {
				node {
					id
					active
					name {
						en
						is
					}
					toppings {
						topping {
							id
							name {
								en
								is
							}
						}
						amount
					}
					priceSmall
					priceMedium
					priceLarge
					canBeDivided
					description {
						en
						is
					}
					imageUrl
					imagePublicId
				}
			}
		}
	}
`

export const INDEX_TOPPINGS = gql`
	query adminIndexToppings($first: Int!, $after: Int!) {
		adminIndexToppings(first: $first, after: $after) {
			totalCount
			pageInfo {
				hasNextPage
			}
			edges {
				node {
					id
					name {
						en
						is
					}
					maxAmount
					category {
						id
						name {
							en
							is
						}
					}
					active
					priceSmall
					priceMedium
					priceLarge
				}
			}
		}
	}
`

export const INDEX_TOPPING_CATEGORIES = gql`
	query adminIndexToppingCategories {
		adminIndexToppingCategories {
			pageInfo {
				hasNextPage
			}
			totalCount
			edges {
				node {
					id
					name {
						en
						is
					}
				}
			}
		}
	}
`

export const INDEX_MENU_CATEGORIES = gql`
	query adminIndexMenuCategories($first: Int!, $after: Int!) {
		adminIndexMenuCategories(first: $first, after: $after) {
			totalCount
			pageInfo {
				hasNextPage
			}
			edges {
				node {
					name {
						en
						is
					}
					id
				}
			}
		}
	}
`

export const INDEX_MENU_ITEMS = gql`
	query adminIndexMenuItems($first: Int!, $after: Int!, $category: ID) {
		adminIndexMenuItems(first: $first, after: $after, category: $category) {
			totalCount
			pageInfo {
				hasNextPage
			}
			edges {
				node {
					id
					price
					active
					category {
						name {
							en
							is
						}
						id
					}
					description {
						en
						is
					}
					name {
						is
						en
					}
					imageUrl
				}
			}
		}
	}
`

export const INDEX_CHANGE_LOG = gql`
	query adminIndexMenuItems($first: Int!, $after: Int!, $model: String) {
		adminIndexChangeLog(first: $first, after: $after, model: $model) {
			pageInfo {
				hasNextPage
			}
			totalCount
			edges {
				node {
					id
					changes {
						code
						hrt
						valuePath
						oldValue
						newValue
					}
					code
					hrt
					updatedAt
					model
					object
					oldObject
					newObject
					changedBy {
						name
					}
				}
			}
		}
	}
`

export const INDEX_CASTELLO_SETTINGS = gql`
	query adminGetCastelloSettings {
		adminGetCastelloSettings {
			basePriceSmallPizza
			basePriceMediumPizza
			basePriceLargePizza
			priceDelivery
			minAmountDelivery
			baseToppings
			frontImageUrl
			frontImagePublicId
			frontTitle {
				en
				is
			}
			frontSubtitle {
				en
				is
			}
		}
	}
`

export const INDEX_ORDER_DATA = gql`
	query adminOrderData ($dates: DateInputType) {
		adminOrderData (dates: $dates) {
			totalOrderPrice
			totalOrders
			branch {
				branch_id
				name
			}
			paymentMethodsSummary {
				paymentMethod
				totalPrice
				totalOrders
			}
			deliveryMethodsSummary {
				deliveryMethod
				totalPrice
				totalOrders
			}
		}
	}
`

export const ADMIN_INDEX_ORDERS = gql`
	query adminIndexOrders($first: Int!, $after: Int!, $branch: ID, $phone: String, $orderNumber: String, $dates: DateInputType) {
		adminIndexOrders(first: $first, after: $after, branch: $branch, phone: $phone, orderNumber: $orderNumber, dates: $dates) {
			pageInfo {
				hasNextPage
			}
			totalCount
			edges {
				node {
					id
					orderNumber
					voided
					status
					paymentMethod
					isPrinted
					deliveryMethod
					totalVat
					totalPrice
					createdAt
					message
					user {
						phone
						name
					}
					totalPriceWithoutVat
					branch {
						name
						branch_id
					}
					deliveryAddress {
						postalCode
						city
						address
						message
					}
					cart {
						name {
							en
							is
						}
						pizzaName2 {
							en
							is
						}
						size
						payload1
						payload2
						isDiscount
						discountPercentage
						price
						priceWithoutDiscount
						discountName
						type
						id
						details {
							type
							name {
								en
								is
							}
							size
							isDivided
							toppings {
								toppingId
								amount
								topping {
									en
									is
								}
							}
							toppings2 {
								toppingId
								amount
								topping {
									en
									is
								}
							}
						}
						isDivided
						toppingsSelectionSide1 {
							amount
							toppingId
							topping {
								en
								is
							}
						}
						toppingsSelectionSide2 {
							amount
							toppingId
							topping {
								en
								is
							}
						}
					}
				}
			}
		}
	}
`