import React from 'react'
import {
  PieChartOutlined,
	UserOutlined,
	DropboxOutlined,
	PercentageOutlined,
	CompassOutlined,
	DiffOutlined,
	SettingOutlined
} from '@ant-design/icons'

const DefaultAdminMenuItems = [
	{
		key: 'home',
		to: '/',
		icon: <PieChartOutlined />,
		name: 'Orders'
	},
	{
		key: 'customer',
		to: '/customer',
		icon: <UserOutlined />,
		name: 'Customers'
	},
	{
		key: 'order-data',
		to: '/order-data',
		name: 'Order Data',
		icon: <DiffOutlined />,
	},
]

const AdminMenuItems = [
	{
		key: 'pizza',
		to: '/pizza',
		name: 'Pizzas',
		icon: <DropboxOutlined />,
	},
	{
		key: 'product',
		to: '/product',
		name: 'Menu Items',
		icon: <DropboxOutlined />,
	},
	{
		key: 'offers',
		to: '/offers',
		name: 'Offers',
		icon: <PercentageOutlined />,
	},
	{
		key: 'discount',
		to: '/discount',
		name: 'Discounts',
		icon: <PercentageOutlined />,
	},
	{
		key: 'branch',
		to: '/branch',
		name: 'Locations',
		icon: <CompassOutlined />,
	},
	{
		key: 'employee',
		to: '/employee',
		name: 'Employees',
		icon: <UserOutlined />,
	},
	{
		key: 'changelog',
		to: '/changelog',
		name: 'Change Log',
		icon: <DiffOutlined />,
	},
	{
		key: 'castello-settings',
		to: '/castello-settings',
		name: 'Castello Settings',
		icon: <SettingOutlined />,
	},
]

export default {
	admin: [...DefaultAdminMenuItems, ...AdminMenuItems],
	employee: DefaultAdminMenuItems
}