import React, { useState, useEffect } from 'react'
import { Layout, Menu, Button, Avatar } from 'antd'
import { Link, useLocation } from "react-router-dom"
import { getRole, getName, deleteToken } from '../../services/token'
import MenuItems from './items'
import { useHistory } from "react-router-dom";

const { Content, Sider } = Layout;

export default function AdminMenu({ children }) {
	const [collapsed, setcollapsed] = useState(false)
	const [loading, setLoading] = useState(true)
	const [menuItems, setMenuItems] = useState([])
	let location = useLocation()
	let history = useHistory();

	useEffect(() => {
		if (!menuItems.length) {
			let items = MenuItems[getRole()]
			setMenuItems(items)
			setLoading(false)
		}
	}, [menuItems.length])

	function logout() {
		deleteToken();
		history.push(`/login`);
	}

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sider
				collapsible
				collapsed={collapsed}
				onCollapse={() => { setcollapsed(!collapsed) }}
				style={{
					overflow: 'auto',
					height: '100vh',
					position: 'fixed',
					left: 0,
				}}
			>
				<div style={{ display: 'flex', margin: '32px 0px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
					<Avatar style={{ backgroundColor: '##B51430', verticalAlign: 'middle' }} size="large" gap={4}>
						{getName()}
					</Avatar>
					{!collapsed &&
						<Button
							size="small"
							style={{ margin: '0 16px', verticalAlign: 'middle' }}
							onClick={logout}
						>
							LOGOUT
						</Button>
					}
				</div>
				<Menu
					activeKey={location.pathname}
					selectedKeys={location.pathname}
					mode="inline"
					theme="dark"
				>
					{!loading && menuItems.map((item, index) => {
						let { key, to, icon, name } = item
						return (
							<Menu.Item key={key}>
								<Link to={to}>
									{icon}
									<span>{name}</span>
								</Link>
							</Menu.Item>
						)
					})}
				</Menu>
			</Sider>
			<Layout style={{ marginLeft: 200 }}>
				<Content style={{ margin: '16px' }}>
					{children}
				</Content>
			</Layout>
		</Layout>
	);
}
