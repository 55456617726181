import React from 'react'
import { Drawer, Button, Icon, message } from 'antd'
import { Formik } from 'formik'
import { Form, Input, Radio } from 'formik-antd'
import * as Yup from 'yup'
import { useMutation } from '@apollo/react-hooks'
import { ADMIN_UPDATE_EMPLOYEE, ADMIN_CREATE_EMPLOYEE, ADMIN_DELETE_EMPLOYEE } from '../../../../graphql/mutations'
import { displayGraphqlErrors } from '../../../../graphql/utils'
import _ from 'lodash'

const EmployeeSchema = Yup.object().shape({
	name: Yup.string()
		.required('Name is required'),
	username: Yup.string()
		.required('User name is required'),
	role: Yup.string()
		.oneOf(['admin', 'employee'])
		.required('Role is required'),
	password: Yup.string()
});

export default function EmployeeDetails({ visible, onClose, employee }) {
	const [adminUpdateEmployee] = useMutation(ADMIN_UPDATE_EMPLOYEE)
	const [adminCreateEmployee] = useMutation(ADMIN_CREATE_EMPLOYEE)
	const [adminDeleteEmployee] = useMutation(ADMIN_DELETE_EMPLOYEE)

	async function handleDelete () {
		try {
			const { data } = await adminDeleteEmployee({ variables: { id: employee.id } })
			message.info(data.adminDeleteEmployee.message)
			onClose(true)
		} catch (err) {
			displayGraphqlErrors(err)
		}
	}

	return (
		<Drawer
			title={employee ? 'Edit Employee' : 'Create Employee'}
			width={720}
			onClose={onClose}
			visible={visible}
			bodyStyle={{ paddingBottom: 80 }}
		>
			<Formik
				enableReinitialize={true}
				initialValues={{
					name: employee ? employee.name : null,
					username: employee ? employee.username : null,
					password: '',
					role: employee ? employee.role : 'employee'
				}}
				validationSchema={EmployeeSchema}
				onSubmit={async values => {
					try {
						if (employee && employee.id) {
							await adminUpdateEmployee({ variables: { id: employee.id, input: _.omit(values, ['password']) }} );
						} else {
							await adminCreateEmployee({ variables: { input: values } })
						}
						onClose(true)
					} catch (err) {
						displayGraphqlErrors(err)
					}
				}}
			>
				{({ errors, touched, handleSubmit, setFieldTouched, setFieldValue, values }) => {
					return (
						<>
							<Form
								layout='vertical'
								hideRequiredMark
							>
								<Form.Item label="Name" name='name' validate={errors.name && touched.name}>
									<Input
										prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
										name='name'
										id="#name"
										value={values.name}
										placeholder="Nafn"
									/>
								</Form.Item>
								<Form.Item label="User name" name='username' validate={errors.username && touched.username}>
									<Input
										prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
										name='username'
										id="#username"
										value={values.username}
										placeholder="User name"
									/>
								</Form.Item>
								{
									!employee &&
									<Form.Item label='Password' name='password' validate={errors.password && touched.password}>
										<Input.Password
											prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
											name='password'
											placeholder="Password"
										/>
									</Form.Item>
								}
								<Form.Item label="Role" name='role' validate={errors.role && touched.role}>
									<Radio.Group name='role' value={values.role}>
										<Radio.Button value={'admin'}>Admin</Radio.Button>
										<Radio.Button value={'employee'}>Employee</Radio.Button>
									</Radio.Group>
								</Form.Item>
							</Form>
							<div
								style={{
									position: 'absolute',
									right: 0,
									bottom: 0,
									width: '100%',
									borderTop: '1px solid #e9e9e9',
									padding: '10px 16px',
									background: '#fff',
									textAlign: 'right',
								}}
								>
								<div style={{
									display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
								}}>
									<div>
										{employee &&
											<Button onClick={handleDelete} type='danger'>
												Delete employee
											</Button>
										}
									</div>
									<div>
										<Button onClick={onClose} style={{ marginRight: 8 }}>
											Cancel
										</Button>
										<Button onClick={handleSubmit} type="primary">
											Save
										</Button>
									</div>
								</div>
							</div>
						</>	
					)
				}}
			</Formik>
		</Drawer>
	)
}
