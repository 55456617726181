import React, { useState } from 'react'
import { Table, Tag, Button } from 'antd'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_OFFERS } from '../../../graphql/queries'
import { Link, useHistory } from 'react-router-dom';

const { Column } = Table;
const PAGE_SIZE = 25;

export default function Offers() {
	const [after, setAfter] = useState(0);
	const history = useHistory();
	const { data, loading, refetch } = useQuery(INDEX_OFFERS, { variables: { first: PAGE_SIZE, after }, fetchPolicy: 'network-only' });


	function handleTableChange(pagination, filters, sorter) {
		const currentPage = pagination.current - 1;
		setAfter(currentPage * PAGE_SIZE);
		refetch();
	};

	return (
		<>
			<Button type='link' onClick={() => history.push('/offers/create')}>
				New Offer
			</Button>
			<Table
				rowKey={record => record.node.id}
				dataSource={data ? data.adminIndexOffers.edges : []}
				pagination={{
					showSizeChanger: false,
					pageSize: PAGE_SIZE,
					defaultCurrent: 1,
					total: data && data.adminIndexOffers.totalCount
				}}
				loading={loading}
				onChange={handleTableChange}
			>
				<Column
					title="Name"
					key="name"
					render={(text, row) => {
						return (
							<span>
								{row.node.name?.en}
							</span>
						)
					}
					}
				/>
				<Column
					title="Delivery Method"
					key="deliveryMethod"
					render={(text, row) => {
						return (
							<span>
								{row.node.isDelivery && <Tag>Delivery</Tag>}
								{row.node.isPickup && <Tag>Pickup</Tag>}
							</span>
						)
					}
					}
				/>
				<Column
					title="Price"
					key="price"
					render={(text, row) => {
						return (
							<span>
								{row.node.price}
							</span>
						)
					}
					}
				/>
				<Column
					title="Action"
					key="action"
					render={(text, record) => (
						<span>
							<Link to={`/offers/${record.node.id}/edit`}>
								Edit
							</Link>
						</span>
					)}
				/>
			</Table>
		</>
	);
}
