
const EMAIL_REGEX = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/
const ZIP_CODE_REGEX = /^\d{3}$/
const PHONE_REGEX = /^(?:\+|[0]{2})?(354)?(:?[\s-])*\d{3}(:?[\s-])*\d{4}$/
const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
const HOUR_MINUTES_REGEX = /^([0-1][0-9]|[2][0-3])([0-5][0-9])$/

export {
  EMAIL_REGEX,
  ZIP_CODE_REGEX,
  PHONE_REGEX,
  URL_REGEX,
  HOUR_MINUTES_REGEX
}
