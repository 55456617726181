export const groupToppings = toppings => {
	var groups = { };
	toppings.forEach(function (item) {
		let { node } = item;
		var list = groups[node.category.name.en];
		if (list) {
			list.toppings.push(node);
		} else {
			groups[node.category.name.en] = {
				toppings: [node]
			}
		}
	});
	return groups;
}
