import React, { useState } from 'react'
import { Table, Tag, Typography } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { INDEX_CHANGE_LOG } from '../../../graphql/queries'
import moment from 'moment'
// import _ from 'lodash'
// import omitDeep from 'omit-deep-lodash';

const { Column } = Table;

const PAGE_SIZE = 25;
export default function ChangeLog() {
  const [after, setAfter] = useState(0);
  const { data, loading, refetch } = useQuery(INDEX_CHANGE_LOG, { variables: { first: PAGE_SIZE, after }, fetchPolicy: 'network-only' });

  function handleTableChange(pagination, filters, sorter) {
    const currentPage = pagination.current - 1;
    setAfter(currentPage * PAGE_SIZE);
    refetch();
  };

  // const renderObject = (object, hasParent, changes) => {
  //   return Object.keys(object).map((key, index) => {
  //     if (_.isObject(object[key])) {
  //       return (
  //         <Descriptions title={key} key={key}>
  //           {renderObject(object[key], true, changes)}
  //         </Descriptions>
  //       )
  //     } else {
  //       let val = object[key] + '';
  //       if (hasParent) {
  //         return <Descriptions.Item label={key}>{val}</Descriptions.Item>;
  //       } else {
  //         return (
  //           <Descriptions title={key} key={key}>
  //             <Descriptions.Item label={key}>{val}</Descriptions.Item>;
  //           </Descriptions>
  //         )
  //       }
  //     }
  //   })
  // }

  // const renderChanges = (changes) => {
  //   return changes.map((item, index) => {
  //     return (
  //       <>
  //         <Typography.Title level={4}>{item.hrt}</Typography.Title>
  //         {item.newValue &&
  //           <Tag color='success'>
  //             {item.newValue}
  //           </Tag>
  //         }
  //         {item.oldValue &&
  //           <Tag color='error'>
  //             <Typography.Text delete>
  //               {item.oldValue}
  //             </Typography.Text>
  //           </Tag>
  //         }
  //         <br />
  //       </>
  //     )
  //   })
  // }

  const expandedRowRender = (record) => {
    const { changes, updatedAt } = record.node;
    // let parsed = omitDeep(JSON.parse(newObject), ['createdAt', 'updatedAt', '_id', 'createdBy', 'updatedBy', '__v', 'id', 'imagePublicId', 'imageMetaData', '__proto__', 'imageUrl']) //Omit basic properties
    return (
      <Table
        bordered
        dataSource={changes}
        rowKey={record => record.code}
        pagination={false}
        footer={() => `Changed at: ${moment.unix(updatedAt / 1000).format('MM/DD/YYYY HH:mm:ss')}`}
      >
        <Column
          title="Code"
          key="code"
          render={(text, row) => {
            return (
              <Tag color='success'>
                {row.code}
              </Tag>
            )
          }
          }
        />
        <Column
          title="Changed Fields"
          key="valuePath"
          render={(text, row) => {
            return row.valuePath.map((item, index) => {
              if (!isNaN(item)) { return null; }
              return (
                <Tag color='#108ee9'>
                  <Typography.Text strong style={{ color: '#FFF' }}>
                    {item.toUpperCase()}
                  </Typography.Text>
                </Tag>
              )
            });
          }}
        />
        <Column
          title="Change"
          key="hrt"
          render={(text, row) => {
            return (
              <span>
                {row.hrt}
              </span>
            )
          }
          }
        />
        <Column
          title="Previous value"
          key="oldValue"
          render={(text, row) => {
            if (row.oldValue) {
              return (
                <Tag color='#f50'>
                  <Typography.Text strong delete style={{ color: '#FFF' }}>
                    {row.oldValue}
                  </Typography.Text>
                </Tag>
              )
            } else {
              return null
            }
          }}
        />
        <Column
          title="New value"
          key="oldValue"
          render={(text, row) => {
            if (row.newValue) {
              return (
                <Tag color='#108ee9'>
                  <Typography.Text strong style={{ color: '#FFF' }}>
                    {row.newValue}
                  </Typography.Text>
                </Tag>
              )
            } else {
              return null
            }
          }}
        />
      </Table>
    )
    // return renderChanges(changes);
  }

  function findRelevantItem(record) {
    let { newObject } = record;
    let parsedObject = JSON.parse(newObject);
    if (parsedObject.name && parsedObject.name.en) {
      return parsedObject.name.en;
    }
    return parsedObject.name;
  }

  return (
    <Table
      expandable={{
        expandedRowRender: record => expandedRowRender(record),
        rowExpandable: record => record.node.changes.length,
      }}
      bordered
      rowKey={record => record.node.id}
      dataSource={data ? data.adminIndexChangeLog.edges : []}
      pagination={{
        showSizeChanger: false,
        pageSize: PAGE_SIZE,
        defaultCurrent: 1,
        total: data && data.adminIndexChangeLog.totalCount
      }}
      loading={loading}
      onChange={handleTableChange}
    >
      <Column
        title="Code"
        key="code"
        render={(text, row) => {
          return (
            <Tag color='processing'>
              {row.node.code}
            </Tag>
          )
        }
        }
      />
      <Column
        title="Change"
        key="hrt"
        render={(text, row) => {
          return (
            <span>
              {row.node.hrt}
            </span>
          )
        }
        }
      />
      <Column
        title="Item"
        key="item"
        render={(text, row) => {
          return (
            <span>
              {findRelevantItem(row.node)}
            </span>
          )
        }
        }
      />
      <Column
        title="Changed by"
        key="changedBy"
        render={(text, row) => {
          return (
            <span>
              {row.node.changedBy.name}
            </span>
          )
        }
        }
      />
      <Column
        title="Date/time"
        key="updatedAt"
        render={(text, row) => {
          return (
            <span>
              {moment.unix(row.node.updatedAt / 1000).format('MM/DD/YYYY HH:mm:ss')}
            </span>
          )
        }
        }
      />
    </Table>
  );
}
