import React, { useState, useEffect } from 'react'
import { Table, Button, Input } from 'antd'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_USERS } from '../../../graphql/queries'
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import CustomerDetails from './components/details';

const { Column } = Table;
const { Search } = Input;
const PAGE_SIZE = 25;
export default function Customers() {
	const [after, setAfter] = useState(0);
	const [visible, setVisible] = useState(false);
	const [phone, setPhone] = useState(null);
	const [user, setUser] = useState(null);
	const { data, loading, refetch } = useQuery(INDEX_USERS, { variables: { first: PAGE_SIZE, after, phone }, fetchPolicy: 'network-only' });

	function handleTableChange(pagination, filters, sorter) {
		const currentPage = pagination.current - 1;
		setAfter(currentPage * PAGE_SIZE);
		refetch();
	};

	useEffect(() => {
		refetch();
	}, [phone, refetch])

	function openDrawer(user) {
		setUser(user);
		setVisible(true);
	}

	function onClose() {
		setUser(null);
		setVisible(false);
		refetch();
	}


	return (
		<>
			<CustomerDetails
				visible={visible}
				onClose={onClose}
				user={user}
			/>
			<Search
				placeholder="Input phone number"
				onSearch={value => setPhone(value)}
				style={{ width: 200 }}
			/>
			<Table
				rowKey={record => record.node.id}
				dataSource={data ? data.adminIndexUsers.edges : []}
				pagination={{
					showSizeChanger: false,
					pageSize: PAGE_SIZE,
					defaultCurrent: 1,
					total: data && data.adminIndexUsers.totalCount
				}}
				bordered
				loading={loading}
				onChange={handleTableChange}
			>
				<Column
					title="Name"
					key="name"
					render={(text, row) => {
						return (
							<span>
								{row.node.name}
							</span>
						)
					}
					}
				/>
				<Column
					title="Phone"
					key="phone"
					render={(text, row) => {
						return (
							<span>
								{row.node.phone}
							</span>
						)
					}
					}
				/>
				<ColumnGroup
					title='Blocked'
					key="block"
				>
					<Column
						title="Is Blocked"
						key="blocked"
						render={(text, row) => (
							<span>
								{row.node.blocked ? 'Blocked' : ''}
							</span>
						)}
					/>
					<Column
						title="Reason"
						key="blockedReason"
						render={(text, row) => (
							<span>
								{row.node.blockedReason}
							</span>
						)}
					/>
				</ColumnGroup>
				<Column
					title="Action"
					key="action"
					render={(text, record) => (
						<span>
							<Button onClick={() => openDrawer(record.node)} type='link'>
								Edit
							</Button>
						</span>
					)}
				/>
			</Table>
		</>
	);
}
