// src/App.js
import React, { Component } from 'react';
import './App.css';
import ApolloProvider from './graphql/apolloProvider';
import { BrowserRouter } from 'react-router-dom'
import routes from './routes';

class App extends Component {
  render() {
    return (
      <ApolloProvider>
    		<BrowserRouter>
          {routes.map((route, index) => {
            return (
              <route.routeType
                key={index}
                path={route.path}
                exact={route.exact}
              >
                <route.layout route={route}>
                  <route.component/>
                </route.layout>
              </route.routeType>
            );
          })}
        </BrowserRouter>
      </ApolloProvider>
    );
  }
}

export default App;