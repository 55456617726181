import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_MENU_ITEMS } from '../../../../../graphql/queries'
import { Select } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { groupMenuItems } from '../../../../../services/menu_items';

const { Option, OptGroup } = Select;

export default function MenuItemSelect ({ onChange, value }) {
	const [groupedMenuItems, setGroupedMenuItems] = useState([]);
	const { data, loading } = useQuery(INDEX_MENU_ITEMS, { variables: { first: 100, after: 0 }, fetchPolicy: 'network-only' });
	
	useEffect(() => {
		if (data && !loading) {
			setGroupedMenuItems(groupMenuItems(data.adminIndexMenuItems.edges))
		}
	}, [loading, data])

	if (loading || !data) {
		return <LoadingOutlined spin/>
	}

	return (
		<Select
			mode="multiple"
			style={{ width: '100%' }}
			placeholder="Please select"
			value={value}
			onChange={onChange}
		>
			{Object.keys(groupedMenuItems).map((item, index) => {
				return (
					<OptGroup label={item} key={item}>
						{groupedMenuItems[item].menuItems.map((menuItem, index) => (
							<Option key={menuItem.id} value={menuItem.id}>{menuItem.name.en} - {menuItem.price}</Option>
						))}
					</OptGroup>
				)
			})}
		</Select>
	)
}
