import React, { useState } from 'react'
import { Table, Button, Tag } from 'antd'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_EMPLOYEES } from '../../../graphql/queries'
import EmployeeDetails from './components/details';

const { Column } = Table;
const PAGE_SIZE = 25;
export default function Employee() {
	const [after, setAfter] = useState(0);
	const [visible, setVisible] = useState(false);
	const [employee, setEmployee] = useState(null);
	const { data, loading, refetch } = useQuery(INDEX_EMPLOYEES, { variables: { first: PAGE_SIZE, after }, fetchPolicy: 'network-only' });

	function handleTableChange (pagination, filters, sorter) {
		const currentPage = pagination.current - 1;
		setAfter(currentPage * PAGE_SIZE);
		refetch();
	};

	function openDrawer (employee) {
		setEmployee(employee);
		setVisible(true);
	}
	
	function onClose () {
		setEmployee(null);
		setVisible(false);
		refetch();
	}

	return (
		<>
			<Button style={{marginBottom: '16px'}} onClick={() => openDrawer(null)}>
				New Employee
			</Button>
			<EmployeeDetails
				visible={visible}
				onClose={onClose}
				employee={employee}
			/>
			<Table
				rowKey={record => record.node.id}
				dataSource={data ? data.adminIndexEmployees.edges : []}
				pagination={{
					showSizeChanger: false,
					pageSize: PAGE_SIZE,
					defaultCurrent: 1,
					total: data && data.adminIndexEmployees.totalCount
				}}
				bordered
				loading={loading}
				onChange={handleTableChange}
			>
				<Column
					title="Name"
					key="name"
					render={(text, row) => {
						return (
							<span>
								{row.node.name}
							</span>
						)
					}
					}
				/>
				<Column
					title="Username"
					key="username"
					render={(text, row) => {
						return (
							<span>
								{row.node.username}
							</span>
						)
					}
					}
				/>
				<Column
					title="Role"
					key="role"
					render={(text, row) => {
						return (
							<span>
								<Tag color={'geekblue'} key={row.node.id}>
									{row.node.role}
								</Tag>
							</span>
						)
					}
					}
				/>
				<Column
					title="Action"
					key="action"
					render={(text, record) => (
						<span>
							<Button onClick={() => openDrawer(record.node)} type='link'>
								Edit
							</Button>
						</span>
					)}
				/>
			</Table>
		</>
	);
}
