import React from 'react'
import { Tabs } from 'antd'
import { PizzaTable, ToppingTable, ToppingCategoryTable } from './tables'

const { TabPane } = Tabs
export default function Pizza() {
	return (
		<Tabs defaultActiveKey="1">
			<TabPane tab="Pizzas" key="1">
				<PizzaTable/>
			</TabPane>
			<TabPane tab="Toppings" key="2">
				<ToppingTable />
			</TabPane>
			<TabPane tab="Topping Categories" key="3">
				<ToppingCategoryTable />
			</TabPane>
		</Tabs>
	)
}

