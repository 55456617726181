import React, { useState } from 'react'
import { Table, Button } from 'antd'
import { useQuery } from '@apollo/react-hooks';
import { INDEX_TOPPING_CATEGORIES } from '../../../../graphql/queries'
import ToppingCategoryDetails from '../components/toppingCategoryDetail';

const { Column } = Table;
const PAGE_SIZE = 25;
export default function ToppingCategoryTable() {
	const [after, setAfter] = useState(0);
	const [visible, setVisible] = useState(false)
	const [toppingCategory, setToppingCategory] = useState(null)
	const { data, loading, refetch } = useQuery(INDEX_TOPPING_CATEGORIES, { variables: { first: PAGE_SIZE, after }, fetchPolicy: 'network-only' });
	
	function handleTableChange (pagination, filters, sorter) {
		const currentPage = pagination.current - 1;
		setAfter(currentPage * PAGE_SIZE);
		refetch();
	};

	function openDrawer (toppingCategory) {
		setToppingCategory(toppingCategory)
		setVisible(true)
	}
	
	function onClose () {
		setToppingCategory(null)
		setVisible(false)
		refetch()
	}

	return (
		<>
			<Button style={{marginBottom: '16px'}} onClick={() => openDrawer(null)}>
				New Topping Category
			</Button>
			<ToppingCategoryDetails
				toppingCategory={toppingCategory}
				visible={visible}
				onClose={onClose}
			/>
			<Table
				rowKey={record => record.node.id}
				dataSource={data ? data.adminIndexToppingCategories.edges : []}
				pagination={{
					showSizeChanger: false,
					pageSize: PAGE_SIZE,
					defaultCurrent: 1,
					total: data && data.adminIndexToppingCategories.totalCount
				}}
				bordered
				loading={loading}
				onChange={handleTableChange}
			>
				<Column
					title="Name"
					key="name"
					render={(text, row) => {
						return (
							<span>
								{row.node.name.en}
							</span>
						)
					}
					}
				/>
				<Column
					title="Action"
					key="action"
					render={(text, record) => (
						<span>
							<Button onClick={() => openDrawer(record.node)} type='link'>
								Edit
							</Button>
						</span>
					)}
				/>
			</Table>
		</>
	);
}
