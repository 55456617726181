import React from 'react'
import {
	Drawer,
	Button,
	Icon,
	Row,
	Col,
	Select,
	message,
	Typography
} from 'antd'
import { Formik, Field as FormikField } from 'formik';
import { Form, Input, Radio } from 'formik-antd'
import * as Yup from 'yup'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ADMIN_UPDATE_MENU_ITEM, ADMIN_CREATE_MENU_ITEM, ADMIN_DELETE_MENU_ITEM, ADMIN_GENERATE_SIGNATURE } from '../../../../graphql/mutations'
import { INDEX_MENU_CATEGORIES } from '../../../../graphql/queries'
import { displayGraphqlErrors } from '../../../../graphql/utils'
import axios from 'axios';
import Dropzone from 'react-dropzone'
import _ from 'lodash'

const { Option } = Select;
const MenuItemSchema = Yup.object().shape({
	name: Yup.object().shape({
		en: Yup.string().required('[EN] Name is required'),
		is: Yup.string().required('[IS] Name is required'),
	}),
	description: Yup.object().shape({
		en: Yup.string().nullable(),
		is: Yup.string().nullable(),
	}),
	price: Yup.number().typeError('Price is required').min(0, 'Price cannot be lower than 0'),
	category: Yup.string().required('Category is required'),
	active: Yup.boolean(),
	imageUrl: Yup.string().nullable(),
	imagePublicId: Yup.string()
});

export default function MenuItemDetails ({ visible, onClose, menuItem, currentCategory }) {
	const { data } = useQuery(INDEX_MENU_CATEGORIES, { variables: { first: 100, after: 0 }, fetchPolicy: 'network-only' })
	const [adminUpdateMenuItem] = useMutation(ADMIN_UPDATE_MENU_ITEM)
	const [adminCreateMenuItem] = useMutation(ADMIN_CREATE_MENU_ITEM)
	const [adminDeleteMenuItem] = useMutation(ADMIN_DELETE_MENU_ITEM)
	const [adminGenerateSignature] = useMutation(ADMIN_GENERATE_SIGNATURE)

	async function handleDelete () {
		try {
			const { data } = await adminDeleteMenuItem({ variables: { id: menuItem.id } })
			message.info(data.adminDeleteMenuItem.message)
			onClose(true)
		} catch (err) {
			displayGraphqlErrors(err)
		}
	}

	async function handleDrop (files, setFieldValue) {
		try {
			const { data } = await adminGenerateSignature()
			const fd = new FormData();
			fd.append("file", files[0]);
			fd.append('signature', data.adminGenerateSignature.signature);
			fd.append("timestamp", data.adminGenerateSignature.timestamp);
			fd.append("public_id", data.adminGenerateSignature.public_id);
			fd.append("api_key",data.adminGenerateSignature.api_key);
			let response = await axios.post("https://api.cloudinary.com/v1_1/dj0wwxv7z/image/upload", fd, {
				headers: { "X-Requested-With": "XMLHttpRequest" },
			})
			const responseData = response.data;
			setFieldValue('imagePublicId', responseData.public_id);
			setFieldValue('imageUrl', responseData.secure_url);
		} catch (err) {
			message.error('Could not upload image');
		}
	}

	return (
		<Drawer
			title={menuItem ? 'Edit Menu Item' : 'Create Menu Item'}
			width={720}
			onClose={onClose}
			visible={visible}
			bodyStyle={{ paddingBottom: 80 }}
		>
			<Formik
				enableReinitialize={true}
				initialValues={{
					name: menuItem ? menuItem.name: { en: '', is: '' },
					description: menuItem ? menuItem.description : { en: '', is: '' },
					price: menuItem ? menuItem.price : null,
					active: menuItem ? menuItem.active : true,
					category: menuItem ? menuItem.category.id : currentCategory, 
					imageUrl: menuItem ? menuItem.imageUrl : '',
					imagePublicId: menuItem ? menuItem.imagePublicId : '',
				}}
				validationSchema={MenuItemSchema}
				onSubmit={async values => {
					try {
						let input = _.omit(values, ['imageUrl']);
						if (menuItem && menuItem.id) {
							await adminUpdateMenuItem({ variables: { id: menuItem.id, input }} );
						} else {
							await adminCreateMenuItem({ variables: { input } })
						}
						onClose(true)
					} catch (err) {
						displayGraphqlErrors(err)
					}
				}}
			>
				{({ errors, touched, handleSubmit, setFieldTouched, setFieldValue, values }) => {
					return (
						<>
							<Form
								layout='vertical'
								hideRequiredMark
							>
								<Dropzone 
									onDrop={(files) => handleDrop(files, setFieldValue)} 
									multiple={false}
									accept="image/*" 
								>
									{({getRootProps, getInputProps}) => (
										<section>
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
												{
													values.imageUrl
														? <img src={values.imageUrl} alt='item' style={{width: 'auto', height: '100px'}}/>
														: <Icon type="inbox" />
												}
													<Typography.Paragraph style={{marginTop: '1rem'}}>Click or drag file to this area to upload</Typography.Paragraph>
												</div>
											</div>
										</section>
									)}
								</Dropzone>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item label="Name [IS]" name='name.is'>
											<Input
												name='name.is'
												value={values.name.is}
												placeholder="Name [IS]"
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Name [EN]" name='name.en'>
											<Input
												name='name.en'
												value={values.name.en}
												placeholder="Name [EN]"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item label="Description [IS]" name='description.is'>
											<Input
												name='description.is'
												value={values.description.is}
												placeholder="Name [IS]"
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Description [EN]" name='description.en'>
											<Input
												name='description.en'
												value={values.description.en}
												placeholder="Description [EN]"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Form.Item label={`Price`} name={`price`}>
											<Input
												type='number'
												name={`price`}
												placeholder={`Price`}
												suffix={'ISK'}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>										
										<Form.Item label="Category" name='category' validate={errors.category && touched.category}>
											<FormikField
												name="category"
												render={({ field }) => {
													if (data && data.adminIndexMenuCategories) {
														return (
															<Select
																{...field}
																onChange={value => setFieldValue("category", value)}
																onBlur={() => setFieldTouched("category", true)}
																value={values.category}
															>
																{data.adminIndexMenuCategories.edges.map(({ node }, index) => (
																	<Option key={node.id} value={node.id}>
																		{node.name.en}
																	</Option>
																))}
															</Select>
														)
													}
													return null;
												}}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Form.Item label="Is active" name='active'>
									<Radio.Group name='active' value={values.active}>
										<Radio.Button value={true}>Active</Radio.Button>
										<Radio.Button value={false}>Not active</Radio.Button>
									</Radio.Group>
								</Form.Item>
							</Form>
							<div
								style={{
									position: 'absolute',
									right: 0,
									bottom: 0,
									width: '100%',
									borderTop: '1px solid #e9e9e9',
									padding: '10px 16px',
									background: '#fff',
									textAlign: 'right',
								}}
								>
								<div style={{
									display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
								}}>
									<div>
										{menuItem &&
											<Button onClick={handleDelete} type='danger'>
												Delete Menu Item
											</Button>
										}
									</div>
									<div>
										<Button onClick={onClose} style={{ marginRight: 8 }}>
											Cancel
										</Button>
										<Button onClick={handleSubmit} type="primary">
											Save
										</Button>
									</div>
								</div>
							</div>
						</>	
					)
				}}
			</Formik>
		</Drawer>
	)
}
