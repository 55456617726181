import React from 'react'
import AdminMenu from '../menu'

// TODO>
// Return relevant menu based on role.
export default function AdminContainer({ children }) {
	return (
		<AdminMenu>
			{children}
		</AdminMenu>
	)
}
