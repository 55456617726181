import * as Yup from 'yup'
import moment from 'moment';
import { HOUR_MINUTES_REGEX } from '../../../../utils/regex'

const TIME_FORMAT = 'HH:mm';

const DEFAULT_PIZZA_ITEM = {
	offerType: "Pizza",
	isMenuPizza: true,
	toppingAmount: null,
	allowedItems: null,
	size: null
}

const DEFAULT_SIDE_ITEM = {
	offerType: "MenuItem",
	isMenuPizza: null,
	toppingAmount: null,
	allowedItems: [],
	size: null
}

const OfferDetailsValidationSchema = Yup.object().shape({
	offerType: Yup.string().oneOf(['Pizza', 'MenuItem']).required(),
	isMenuPizza: Yup.boolean().nullable(),
	toppingAmount: Yup.number().min(0).nullable(),
	allowedItems: Yup
		.array(Yup.string())
		.nullable()
		.test('is-menu-pizza', 'You have to select items that are allowed', function (value) {
			if (this.parent.isMenuPizza) {
				if (value && value.length === 0) {
					return false;
				}
			}
			return true;
		}),
	size: Yup
					.string()
					.oneOf(['small', 'medium', 'large', null])
					.nullable()
					.test('is-pizza', 'You need to select a size for the pizza', function (value) {
						const { offerType } = this.parent;
						if (offerType === 'Pizza' && value === null) {
							return false;
						}
						return true;
					})
})

const OfferValidationSchema = Yup.object().shape({
	name: Yup.object().shape({
		en: Yup.string().required('[EN] Name is required'),
		is: Yup.string().required('[IS] Name is required'),
	}),
	description: Yup.object().shape({
		en: Yup.string().nullable(),
		is: Yup.string().nullable(),
	}),
	price: Yup.number('Price is required').min(0, 'Price cannot be lower than 0').typeError('Price is required'),
	isDelivery: Yup
		.boolean()
		.nullable()
		.test('is-delivery-test', 'You must specify either delivery or pickup', function(value) {
			// Return false, if isPickup is set to false AND is delivery is set to false.
			if (!value && !this.parent.isPickup) {
				return false
			}
			return true;
		}),
	isPickup: Yup
		.boolean()
		.nullable()
		.test('is-pickup-test', 'You must specify either delivery or pickup', function(value) {
			// Return false, if isPickup is set to false AND is delivery is set to false.
			if (!value && !this.parent.isDelivery) {
				return false
			}
			return true;
		}),
	active: Yup.boolean(),
	imageUrl: Yup.string().nullable(),
	imagePublicId: Yup.string(),
	offerValidDays: Yup.array(Yup.string()).min(1, 'You need to select at least one day').max(7),
	offerValidHours: Yup.object().shape({
		from: Yup.string().required('You need to add from time').matches(HOUR_MINUTES_REGEX, 'Time is not in correct format'),
		to: Yup
					.string()
					.required('You need to add to time')
					.matches(HOUR_MINUTES_REGEX, 'Time is not in correct format')
					.test('is-greater', 'Time to cannot come before from',  function (value) {
						const { from } = this.parent;
						return moment(value, 'HH:mm').isAfter(moment(from, 'HH:mm'))
					})
	}).nullable(),
	details: Yup.array().of(OfferDetailsValidationSchema).min(1, 'You need to add at least 1 item')
});

export {
	OfferValidationSchema,
	TIME_FORMAT,
	DEFAULT_PIZZA_ITEM,
	DEFAULT_SIDE_ITEM
}